import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, Redirect, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import { useAuthUser, useScrollToTop } from 'hooks';
import { CreateBottomNavBar, CreateTopNavBar, CreateHeader, Timeline, Divider } from 'components';
import { FaRegSave } from 'react-icons/fa';
import routeConstants from '../App/routeConstants';
import CreateProperty from './createProperty';
import CreatePropertyOwner from './createPropertyOwner';
import AddListingItems from './addListingItems';
import AddListingReports from './addListingReports';
import AddCustomUrlModal from './addCustomUrlModal';
import RPAAddenda from './rpaAddenda';
import ListingReview from './listingReview';
import RPAClauses from './rpaClauses';
import { initListing } from './listingObj';
import routes from 'containers/App/routeConstants';
import { ContinueButton, ListingAgentPending } from './createNewListingPage.styled';
import { listingSteps, propertyAddendaItemsCA, propertyAddendaItemsFL } from 'constants/listingConstants';
import { CreateListingAsyncActions } from 'store/actions/createListing';
import { SubscriptionsAsyncActions } from 'store/actions/subscriptions';
import { isNil } from 'lodash';
import { getCreateListing } from 'store/selectors/createListing';
import { getStateIncludedItems } from 'store/selectors/listings';
import { getSubscription, getPricing } from 'store/selectors/subscriptions';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { validate } from 'isemail';

const MySwal = withReactContent(Swal);

const START_STEP = 1;
const END_STEP = 7;

const CreateNewListingPage = ({ currentListing, offerCount = -1 }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const locationState = useLocation().state;
  const [listing, setListing] = useState(initListing);
  const [isReview, setIsReview] = useState(currentListing ? true : false);
  const [stepNumber, setStepNumber] = useState(currentListing ? END_STEP : 1);
  const [showSavedNow, setShowSavedNow] = useState(false);
  const [showCustomUrl, setShowCustomUrl] = useState(false);
  const [errors, setErrors] = useState({
    property: {},
    items: {},
    services: {},
    propertyOwner: {},
    secondaryOwners: {},
    rpaAddenda: {},
  });

  useScrollToTop([stepNumber]);

  const { isSuccess: isCreatedSuccess, error: createError } = useSelector(
    CreateListingAsyncActions.CreateListing.StatusSelector()
  );
  const { error: updateError } = useSelector(CreateListingAsyncActions.UpdateListing.StatusSelector());
  const { isSuccess: isUpdatedStateSuccess, error: updateStateError } = useSelector(
    CreateListingAsyncActions.UpdateState.StatusSelector()
  );
  const { isSuccess: isSendListingToSellerSuccess, error: sendListingToSellerError } = useSelector(
    CreateListingAsyncActions.SendListingToSeller.StatusSelector()
  );
  const createdListing = useSelector(getCreateListing);
  const stateIncludedOptions = useSelector(getStateIncludedItems);
  const subscription = useSelector(getSubscription);
  const pricingTableId = useSelector(getPricing);

  const validSubscription = useMemo(() => {
    return subscription?.active;
  }, [subscription]);

  const { isAgent, isListingAgent, isSellerAgent, isPrimaryOwner, isSecondaryOwner, user } = useAuthUser({
    listing,
  });

  useEffect(() => {
    dispatch(SubscriptionsAsyncActions.FetchSubscription.Actions.REQUEST());
    return () => {
      dispatch(CreateListingAsyncActions.CreateListing.Actions.RESET());
      dispatch(CreateListingAsyncActions.UpdateListing.Actions.RESET());
      dispatch(CreateListingAsyncActions.UpdateState.Actions.RESET());
      dispatch(CreateListingAsyncActions.SendListingToSeller.Actions.RESET());
      dispatch(CreateListingAsyncActions.EditListingURL.Actions.RESET());
      dispatch(SubscriptionsAsyncActions.FetchSubscription.Actions.RESET());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!currentListing && isAgent && !isListingAgent) {
      MySwal.fire({
        html: (
          <div>
            <p>We are excited you are interested in posting a listing on the OFFER1 platform.</p>
            <p>
              Please note there is an application process (for listings only) and upon completion, your listing will not
              be shared UNTIL your application is approved.
            </p>
            <p>For your benefit we want to insure you and your seller have the best possible experience.</p>
            <p>Happy Selling!</p>
          </div>
        ),
      });
    }
  }, [currentListing, isAgent, isListingAgent]);

  useEffect(() => {
    if (currentListing) {
      setListing(currentListing);
    } else if (locationState) {
      setListing(locationState.listing);
    }
  }, [currentListing, locationState]);

  useEffect(() => {
    if (isCreatedSuccess) {
      setListing(createdListing);
      setShowSavedNow(true);
    }
  }, [dispatch, isCreatedSuccess, createdListing]);

  useEffect(() => {
    if (isUpdatedStateSuccess || isSendListingToSellerSuccess) {
      setListing(createdListing);

      const title =
        createdListing.state === 'SELLER_APPROVED'
          ? isSellerAgent
            ? 'Your Listing has been unpublished'
            : 'You have successfully approved your Listing'
          : createdListing.state === 'PENDING_AGENT_UPDATES'
          ? 'Your requested changes have been sent'
          : createdListing.state === 'ACTIVE'
          ? 'Congratulations! Your Listing has been published.'
          : 'Your Listing has been updated';

      const transitionMessage =
        createdListing.state === 'SELLER_APPROVED'
          ? isSellerAgent
            ? 'Your Listing has been successfully unpublished.'
            : 'Your agent will be notified.'
          : createdListing.state === 'PENDING_AGENT_UPDATES'
          ? 'Your agent will be notified. Once they have taken the corrective action they can resend you the listing for your approval/review'
          : createdListing.state === 'ACTIVE'
          ? 'Your listing is now Active.'
          : 'Below are the steps to get your listing LIVE on the Offer1 Platform.';

      MySwal.fire({
        title,
        html: (
          <>
            <p>{transitionMessage}</p>
            <Timeline
              steps={['Create Listing (Agent)', 'Active Listing (Offer1)']}
              step={createdListing.state === 'DRAFT' ? 0 : 1}
            />
          </>
        ),
        confirmButtonText: 'Continue',
        allowOutsideClick: false,
      }).then(() => {
        history.push(routeConstants.LISTINGS_PATH_ALL);
      });
    }
  }, [history, isUpdatedStateSuccess, isSendListingToSellerSuccess, createdListing, isSellerAgent]);

  const handleContinue = () => {
    if (stepNumber <= 0 || checkRequirements(stepNumber)) {
      return;
    }
    if (stepNumber === END_STEP - 1) {
      setIsReview(true);
      saveListing();
      setStepNumber(stepNumber + 1);
    } else {
      saveListing();
      setStepNumber(stepNumber + 1);
    }
  };

  const checkError = (err, selectors) => {
    return Object.keys(err).some((key) => {
      const el = err[key];
      if (el && typeof el === 'object') {
        return checkError(el);
      }
      if (el) {
        Swal.fire({
          icon: 'warning',
          title: 'Some Answers Required',
          text: 'Please fill out all fields marked with an asterisk to continue.',
          confirmButtonText: 'Continue',
        }).then(() => {
          setTimeout(() => {
            document.querySelector(`${selectors?.[key] || '#' + key}`)?.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          }, 300);
        });
      }
      return !!el;
    });
  };

  const checkRequirements = (stepNumber) => {
    switch (stepNumber) {
      case 1: {
        const { price, address, propertyType, squareFeet, numberBedrooms, numberBaths } = listing;
        const propertyError = {
          price: !price,
          address: {
            addressLine1: !address?.addressLine1,
            city: !address?.city,
            state: !address?.state,
            zip: !address?.zip,
          },
          propertyType: !propertyType,
          squareFeet: isNil(squareFeet),
          numberBedrooms: isNil(numberBedrooms),
          numberBaths: isNil(numberBaths),
        };
        setErrors((prevErrors) => ({
          ...prevErrors,
          property: propertyError,
        }));
        return checkError(propertyError);
      }
      case 2: {
        const {
          tenantOccupiedPostCoe,
          assignNo,
          assignLiability,
          proofOfFundsRequired,
          sellerFinancingAvailable,
          address,
        } = listing;
        const listingState = address.state;
        const isFlorida = listingState === 'FL';

        const rpaError = {
          tenantOccupiedPostCoe: isFlorida && isNil(tenantOccupiedPostCoe),
          assignNo: isFlorida && isNil(assignNo),
          assignLiability: isFlorida && assignNo && isNil(assignLiability),
          proofOfFundsRequired: isNil(proofOfFundsRequired),
          sellerFinancingAvailable: isFlorida && isNil(sellerFinancingAvailable),
        };
        setErrors((prevErrors) => ({
          ...prevErrors,
          rpa: rpaError,
        }));
        return checkError(rpaError);
      }

      case 3: {
        const { hasIncludedItems, includedItems, hasExcludedItems, excludedItems /* hasLienItems, lienItems */ } =
          listing;
        const itemsError = {
          includedItems: isNil(hasIncludedItems) || (hasIncludedItems && !includedItems.length),
          excludedItems: isNil(hasExcludedItems) || (hasExcludedItems && !excludedItems.length),
          // lienItems: isNil(hasLienItems) || (hasLienItems && !lienItems.length),
        };
        setErrors((prevErrors) => ({
          ...prevErrors,
          items: itemsError,
        }));
        return checkError(itemsError);
      }
      case 4: {
        const { titleCompany, escrowCompany, hazardCompany, address } = listing;
        const servicesError = {
          titleCompany: { titleCompanyName: !titleCompany?.name },
          escrowCompany: { escrowCompanyName: !escrowCompany?.name },
          hazardCompany: address.state === 'CA' && { hazardCompanyName: !hazardCompany?.name },
        };
        setErrors((prevErrors) => ({
          ...prevErrors,
          services: servicesError,
        }));
        return checkError(servicesError);
      }
      case 5: {
        const { primaryOwner, certifyOwnersCorrect, secondaryOwners } = listing;
        const emailError = !primaryOwner?.user?.email
          ? 'Email is required'
          : !validate(primaryOwner?.user?.email || '')
          ? 'Invalid email'
          : primaryOwner?.user?.email === user.email
          ? 'You cannot use your own email'
          : secondaryOwners?.some((owner) => owner.user.email === primaryOwner?.user?.email)
          ? 'Primary owner email cannot be the same as secondary owner email'
          : '';
        const propertyOwnerError = {
          primaryOwner: !primaryOwner,
          user: {
            firstName: !primaryOwner?.user?.firstName,
            lastName: !primaryOwner?.user?.lastName,
            email: emailError,
            phone: !primaryOwner?.user?.phone,
          },
          address: {
            addressLine1: !primaryOwner?.address?.addressLine1,
            city: !primaryOwner?.address?.city,
            state: !primaryOwner?.address?.state,
            zip: !primaryOwner?.address?.zip,
          },
          certifyOwnersCorrect: certifyOwnersCorrect !== true,
        };
        const secondaryOwnerErrors = secondaryOwners?.map((owner, index) => ({
          user: {
            firstName: !owner?.user?.firstName && 'First Name is required',
            lastName: !owner?.user?.lastName && 'Last Name is required',
            email: !validate(owner?.user?.email || '')
              ? 'Invalid email'
              : owner?.user?.email === user.email
              ? 'You cannot use your own email'
              : primaryOwner?.user?.email === owner?.user?.email
              ? 'Primary owner email cannot be the same as secondary owner email'
              : secondaryOwners?.some((o, i) => o.user.email === owner?.user?.email && i !== index)
              ? 'Secondary owner email cannot be the same as another secondary owner email'
              : '',
            phone: !owner?.user?.phone && 'Phone is required',
          },
          address: {
            addressLine1: !owner?.address?.addressLine1 && 'Address is required',
            city: !owner?.address?.city && 'City is required',
            state: !owner?.address?.state && 'State is required',
            zip: !owner?.address?.zip && 'Zip is required',
          },
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          primaryOwner: propertyOwnerError,
          secondaryOwners: secondaryOwnerErrors,
        }));
        return checkError({
          primaryOwner: propertyOwnerError,
          secondaryOwners: secondaryOwnerErrors,
        });
      }
      case 6: {
        const {
          customAddendum,
          address,
          propertyAddenda,
          remainInPropertyAnswer,
          remainInPropertyFile,
          customAddendumFile,
        } = listing;
        const propertyAddendaItems = address.state === 'FL' ? propertyAddendaItemsFL : propertyAddendaItemsCA;

        const rpaAddendaError = {
          propertyAddenda: propertyAddendaItems.reduce((acc, addendum) => {
            if (propertyAddenda?.[addendum.name] && !propertyAddenda?.[`${addendum.name}File`]) {
              acc[addendum.name] = true;
            }
            return acc;
          }, {}),
          remainInProperty:
            address.state === 'CA' &&
            !!remainInPropertyAnswer &&
            remainInPropertyAnswer !== 'no' &&
            !remainInPropertyFile,
          customAddendum: address.state === 'CA' && isNil(customAddendum),
          customAddendumFile: address.state === 'CA' && customAddendum && !customAddendumFile,
        };
        setErrors((prevErrors) => ({
          ...prevErrors,
          rpaAddenda: rpaAddendaError,
        }));
        return checkError(rpaAddendaError);
      }
      default:
        return false;
    }
  };

  const handleBack = (backToReview = false) => {
    if (checkRequirements(stepNumber)) {
      return;
    }
    if (backToReview) {
      setStepNumber(END_STEP);
    } else if (stepNumber > 1) {
      setStepNumber(stepNumber - 1);
    }
    saveListing();
  };

  const handleDelete = () => {
    dispatch(CreateListingAsyncActions.DeleteListing.Actions.REQUEST(listing.id));
  };

  const handlePropertyChanged = ({ field, value }) => {
    setShowSavedNow(false);
    setListing((prevListing) => ({ ...prevListing, [field]: value }));
  };

  const handlePropertyOwnerChanged = (field, value) => {
    setShowSavedNow(false);

    setListing((prevListing) => {
      const updatedListing = { ...prevListing };
      if (!updatedListing.primaryOwner) {
        updatedListing.primaryOwner = {};
      }
      switch (field) {
        case 'ownerAddress':
          updatedListing.primaryOwner.address = value;
          break;
        case 'owner':
          updatedListing.primaryOwner.user = value;
          break;
        case 'ownerType':
          updatedListing.ownerType = value;
          updatedListing.primaryOwner.ownerType = value;
          break;
        case 'orgName':
          updatedListing.primaryOwner.orgName = value;
          break;
        case 'secondaryOwners':
          updatedListing.secondaryOwners = value;
          break;
        case 'acknowledgePrimarySeller':
          updatedListing.acknowledgePrimarySeller = value;
          break;
        case 'confirmAuthorizationToSell':
          updatedListing.confirmAuthorizationToSell = value;
          break;
        case 'certifyOwnersCorrect':
          updatedListing.certifyOwnersCorrect = value;
          break;
        default:
          break;
      }

      return updatedListing;
    });
  };

  const usePropertyAddress = (useDefault) => {
    setShowSavedNow(false);
    if (useDefault) {
      setListing((prevListing) => {
        const updatedListing = { ...prevListing };
        updatedListing.primaryOwner = listing.primaryOwner ? listing.primaryOwner : {};
        updatedListing.primaryOwner.address = {
          ...listing.address,
          id: null,
        };

        return updatedListing;
      });
    }
  };

  const handleListingItemsChanged = (updatedListing) => {
    setShowSavedNow(false);
    setListing(updatedListing);
  };

  const handlePropertyImageChanged = (uploadedFile) => {
    setShowSavedNow(false);

    setListing((prevListing) => ({
      ...prevListing,
      primaryImage: uploadedFile,
    }));
  };

  const handlePropertyImageDelete = () => {
    setShowSavedNow(false);
    setListing((prevListing) => ({ ...prevListing, primaryImage: null }));
  };

  const getFileField = (fileType) => {
    return (
      {
        INSPECTION: 'inspectionReport',
        TERMITE: 'termiteReport',
        QUESTIONNAIRE: 'questionnaireReport',
        TITLE_PRELIM: 'titlePrelimReport',
      }[fileType] ?? 'listingReports'
    );
  };

  const handleListingReportsChanged = (uploadedFile, fileType) => {
    setShowSavedNow(false);
    const field = getFileField(fileType);

    if (field === 'listingReports') {
      if (listing.listingReports) {
        setListing((prevListing) => ({
          ...prevListing,
          listingReports: [...prevListing.listingReports, uploadedFile],
        }));
      } else {
        setListing((prevListing) => ({ ...prevListing, listingReports: [uploadedFile] }));
      }
    } else {
      setListing((prevListing) => ({ ...prevListing, [field]: uploadedFile }));
    }
  };

  const handleListingReportsDelete = (fileType, fileId) => {
    setShowSavedNow(false);
    const field = getFileField(fileType);
    if (field === 'listingReports') {
      setListing((prevListing) => ({
        ...prevListing,
        listingReports: [...prevListing.listingReports.filter(({ id }) => id !== fileId)],
      }));
    } else {
      setListing((prevListing) => ({ ...prevListing, [field]: null }));
    }
  };

  const handlePrimaryOwnerReportsChanged = (docType, uploadedFile) => {
    setShowSavedNow(false);
    setListing((prevListing) => ({
      ...prevListing,
      primaryOwner: { ...prevListing.primaryOwner, [docType]: uploadedFile },
    }));
  };

  const handlePrimaryOwnerReportsDelete = (docType) => {
    setShowSavedNow(false);
    setListing((prevListing) => ({ ...prevListing, primaryOwner: { ...prevListing.primaryOwner, [docType]: null } }));
  };

  const handleStep = (step) => {
    setStepNumber(step);
  };

  const handleRPA = (field, data) => {
    setShowSavedNow(false);
    setListing((prevListing) => ({ ...prevListing, [field]: data }));
  };

  const handleServicesChanged = (data) => {
    setShowSavedNow(false);
    setListing((prevListing) => ({ ...prevListing, [data.field]: data.value }));
  };

  const handleRPAAddendaChanged = (data) => {
    setShowSavedNow(false);
    setListing((prevListing) => ({ ...prevListing, [data.field]: data.value }));
  };

  const saveListing = (newListing = listing) => {
    setShowSavedNow(false);
    if (isNil(newListing.id)) {
      dispatch(CreateListingAsyncActions.CreateListing.Actions.REQUEST(newListing));
    } else {
      dispatch(CreateListingAsyncActions.UpdateListing.Actions.REQUEST(newListing));
    }
  };

  const updateListingState = (status, comment) => {
    dispatch(CreateListingAsyncActions.UpdateState.Actions.REQUEST(listing.id, status, comment));
  };

  const handleUpdateListing = async (newState) => {
    if (newState === 'PENDING_SELLER_APPROVAL') {
      const { value: confirmAuthorizationToSell } = await Swal.fire({
        title: 'Send to Seller',
        text: 'In order to receive Seller Authorization you must have a signed agreement with the seller to list this property for sale.',
        input: 'radio',
        inputLabel: 'Do you have a signed agreement?',
        inputOptions: {
          yes: 'Yes',
          no: 'No',
        },
        showCancelButton: true,
        confirmButtonText: 'Send To Seller',
        inputValidator: (result) => {
          return result !== 'yes' && 'You need to select Yes.';
        },
      });

      if (confirmAuthorizationToSell) {
        const updatedListing = {
          ...listing,
          state: newState,
          confirmAuthorizationToSell: true,
        };
        setListing(updatedListing);
        dispatch(CreateListingAsyncActions.SendListingToSeller.Actions.REQUEST(updatedListing));
      }
    } else if (newState === 'PENDING_AGENT_UPDATES') {
      const { isConfirmed, value: comment } = await Swal.fire({
        title: 'Reject Agent Draft Proposal',
        input: 'textarea',
        text: 'Once you click the button below your agent will be notified to make any corrections or updates you requested. When your agent makes the correction you will be re-sent an email to review and approve the listing.',
        showCancelButton: true,
        confirmButtonText: 'Send back to Agent for Changes',
        inputValidator: (result) => {
          return !result && 'This field is required';
        },
      });

      if (isConfirmed) {
        updateListingState(newState, comment);
      }
    } else if (newState === 'UNPUBLISH') {
      const { isConfirmed } = await Swal.fire({
        title: 'Unpublish listing',
        text: 'Unpublishing your Listing will make it not visible to others and unsearchable. No offers will be collected once unpublished. You will only be allowed to unpublish a listing when there are NO offers. Do you wish to continue?',
        showCancelButton: true,
        confirmButtonText: 'Unpublish',
      });

      if (isConfirmed) {
        updateListingState('DRAFT');
      }
    } else {
      updateListingState(newState);
    }
  };

  const handleCloseCustomUrlModal = (customUrl) => {
    if (customUrl) {
      const updatedListing = { ...listing, customListingUrl: customUrl };
      setListing(updatedListing);
      setShowSavedNow(false);
      dispatch(CreateListingAsyncActions.EditListingURL.Actions.REQUEST(updatedListing));
    }
    setShowCustomUrl(false);
  };

  if (!listing) {
    return <Redirect to={routeConstants.LISTINGS_PATH_ALL} />;
  }

  const isError = !!createError || !!updateError || !!updateStateError || !!sendListingToSellerError;

  return (
    <>
      {!currentListing && (
        <Helmet>
          <title>Create a listing</title>
          <meta name='description' content='Create a listing' />
        </Helmet>
      )}

      {!isAgent && !isPrimaryOwner && !isSecondaryOwner && (
        <ListingAgentPending>
          <h3>Looks like you aren't approved to create Listings</h3>
          <h5>Ensure you've completed the Listing Agent application!</h5>
          <h5>
            If you've completed the Listing application you will be notified once your application has been approved.
          </h5>
          <ContinueButton className='mt-3' onClick={() => history.push(routes.AGENT_PROFILE_PATH)}>
            COMPLETE AGENT PROFILE
          </ContinueButton>
        </ListingAgentPending>
      )}
      {isError && <h4 className='mx-auto mt-4'>Something went wrong with saving your listing</h4>}
      {(isAgent || isPrimaryOwner || isSecondaryOwner) && !isError && (
        <React.Fragment>
          <CreateTopNavBar
            isEnd={stepNumber === END_STEP}
            isSellerAgent={isSellerAgent}
            isPrimaryOwner={isPrimaryOwner}
            status={listing.state}
            onUpdate={(newState) => handleUpdateListing(newState)}
            isListing
            validSubscription={validSubscription}
          />
          <CreateHeader
            stepNumber={stepNumber}
            stepName={listingSteps[stepNumber - 1].name}
            totalSteps={listingSteps.length}
          />
          <Divider className='mx-md-0' />
          <div className={stepNumber !== 7 ? 'py-4 px-md-4 px-2' : 'pt-1'}>
            {stepNumber === 1 && (
              <CreateProperty
                property={listing}
                onStateChanged={handlePropertyChanged}
                errors={errors.property}
                primaryImage={listing.primaryImage}
                licenseState={user.agent.licenseState}
                onPropertyImageChanged={handlePropertyImageChanged}
                onPropertyImageDelete={handlePropertyImageDelete}
              />
            )}
            {stepNumber === 2 && (
              <RPAClauses
                rpaBreachContract={listing.rpaBreachContract}
                rpaDisputeResolution={listing.rpaDisputeResolution}
                proofOfFundsRequired={listing.proofOfFundsRequired}
                tenantOccupiedPostCoe={listing.tenantOccupiedPostCoe}
                sellerFinancingAvailable={listing.sellerFinancingAvailable}
                assignNo={listing.assignNo}
                assignLiability={listing.assignLiability}
                listingState={listing.address.state}
                errors={errors.rpa}
                onStateChanged={handleRPA}
              />
            )}
            {stepNumber === 3 && (
              <AddListingItems
                listing={listing}
                onListingItemsChanged={handleListingItemsChanged}
                stateIncludedOptions={stateIncludedOptions}
                listingState={listing.address.state}
                errors={errors.items}
              />
            )}
            {stepNumber === 4 && (
              <AddListingReports
                listingState={listing.address.state}
                inspectionReport={listing.inspectionReport}
                termiteReport={listing.termiteReport}
                questionnaireReport={listing.questionnaireReport}
                titlePrelimReport={listing.titlePrelimReport}
                listingReports={listing.listingReports}
                onStateChanged={handleListingReportsChanged}
                deleteFile={handleListingReportsDelete}
                onServicesChanged={handleServicesChanged}
                listing={listing}
                errors={errors.services}
              />
            )}
            {stepNumber === 5 && (
              <CreatePropertyOwner
                owner={listing.primaryOwner ? listing.primaryOwner.user : {}}
                ownerAddress={listing.primaryOwner ? listing.primaryOwner.address : {}}
                ownerReports={listing.primaryOwner}
                secondaryOwners={listing.secondaryOwners || []}
                defaultAddress={listing.address}
                ownerType={listing.ownerType}
                acknowledgePrimarySeller={listing.acknowledgePrimarySeller}
                orgName={listing.primaryOwner?.orgName}
                certifyOwnersCorrect={listing.certifyOwnersCorrect}
                onStateChanged={handlePropertyOwnerChanged}
                onPropertyAddress={usePropertyAddress}
                onFileChanged={handlePrimaryOwnerReportsChanged}
                onFileDeleted={handlePrimaryOwnerReportsDelete}
                errors={errors.primaryOwner}
                secondaryOwnersErrors={errors.secondaryOwners}
              />
            )}
            {stepNumber === 6 && (
              <RPAAddenda
                listingState={listing.address.state}
                propertyAddenda={listing.propertyAddenda}
                remainInPropertyAnswer={listing.remainInPropertyAnswer}
                remainInPropertyFile={listing.remainInPropertyFile}
                customAddendum={listing.customAddendum}
                customAddendumFile={listing.customAddendumFile}
                advisories={listing.advisories}
                errors={errors.rpaAddenda}
                onStateChanged={handleRPAAddendaChanged}
              />
            )}
            {stepNumber === 7 && (
              <ListingReview
                onHandleJumpToStep={handleStep}
                listing={listing}
                isListingAgent={isSellerAgent}
                onEditUrl={() => setShowCustomUrl(true)}
              />
            )}
          </div>
          {stepNumber !== 7 && <Divider className='mx-md-0' />}
          <CreateBottomNavBar
            isListing
            showBack={stepNumber > START_STEP && isAgent}
            isEnd={stepNumber === END_STEP}
            showContinue={stepNumber !== END_STEP}
            isReview={isReview}
            onBack={handleBack}
            onContinue={handleContinue}
            onUpdateStatus={handleUpdateListing}
            isSellerAgent={isSellerAgent}
            isPrimaryOwner={isPrimaryOwner}
            offerCount={offerCount}
            status={listing.state}
            showDelete={(isSellerAgent || isPrimaryOwner) && stepNumber === END_STEP}
            onDelete={handleDelete}
            validSubscription={validSubscription}
            pricingTable={pricingTableId}
          />
          {showSavedNow && (
            <div className='ms-2 d-flex align-items-center'>
              <FaRegSave size={20} className='me-2' />
              Saved Now
            </div>
          )}
        </React.Fragment>
      )}
      {showCustomUrl && (
        <AddCustomUrlModal
          show={showCustomUrl}
          title={'Customize your URL:'}
          listingUrl={listing.customListingUrl}
          onContinue={handleCloseCustomUrlModal}
          onClose={() => setShowCustomUrl(false)}
        />
      )}
    </>
  );
};

export default CreateNewListingPage;
