import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import auth from './auth';
import app from './app';
import dashboard from './dashboard';
import admin from './admin';
import listings from './listings';
import buyers from './buyers';
import offers from './offers';
import agent from './agent';
import createOffer from './createOffer';
import createListing from './createListing';
import contract from './contract';
import subscriptions from './subscriptions';
import { AuthAsyncActions } from 'store/actions/auth';

const createRootReducer = (history) => {
  const appReducer = combineReducers({
    router: connectRouter(history),
    auth,
    app,
    dashboard,
    admin,
    listings,
    buyers,
    offers,
    agent,
    createOffer,
    createListing,
    contract,
    subscriptions,
  });

  const rootReducer = (state, action) => {
    if (action.type === AuthAsyncActions.LogOut.Types.REQUEST) {
      return appReducer(
        {
          router: state.router,
          auth: state.auth,
          app: state.app,
        },
        action
      );
    }
    return appReducer(state, action);
  };

  return rootReducer;
};

export default createRootReducer;
