import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import { MdEdit } from 'react-icons/md';
import { Collapse, FileSelect, PropertyItem, CheckBox, Typography, Icon } from 'components';
import OfferFieldSummary from './offerFieldSummary';
import moment from 'moment';
import { formatCurrency, formatPrettyPercentage, formatPhoneNumber, URLUtils } from 'utils';
import { buyerAddendaItemsCA, buyerAddendaItemsFL } from 'constants/offerConstants';
import { isNil } from 'lodash';
import OfferSellerAddenda from './offerSellerAddenda';
import { Header, CheckBoxContainer } from './offerReview.styled';
import OfferCostAllocationTableCA from './offerCostAllocationTableCA';
import OfferCostAllocationTableFL from './offerCostAllocationTableFL';
import { propertyAddendaItemsCA, propertyAddendaItemsFL } from 'constants/listingConstants';

function OfferReview({
  listing = null,
  offer,
  steps,
  onStateChanged,
  onHandleJumpToStep,
  isBuyerAgent,
  isBuyer,
  readOnly = false,
  collapseAll = false,
  editLabel = 'Edit',
  isCounter,
  errors,
}) {
  const servicePayerOptions = useMemo(
    () => [
      { title: 'Buyer', payer: 'BUYER' },
      { title: 'Seller', payer: 'SELLER' },
    ],
    []
  );

  const typeMap = useMemo(
    () => ({
      PROOF_FUNDS: 'proofOfFunds',
      LOAN_APPROVAL: 'loanApproval',
      SELLER_LETTER: 'letterToSeller',
      OTHER: 'additionalOfferDocument',
    }),
    []
  );

  const getPaymentMethod = (terms) => {
    if (isNil(terms.loan)) return 'Not specified';
    return terms.loan ? (terms.loanType ? terms.loanType : 'Loan') : 'Cash';
  };

  const getClosingText = () => {
    return `Paid by Seller: ${formatPrettyPercentage(
      offer.creditItems?.closingCostCreditPercentage,
      2
    )} = ${formatCurrency(offer.creditItems?.closingCostCreditDollar)}`;
  };

  const getSellerPaysBuyerBroker = () => {
    return `Paid by Seller: ${formatPrettyPercentage(
      offer.creditItems?.sellerPaysBuyerBrokerPercentage,
      2
    )} = ${formatCurrency(offer.creditItems?.sellerPaysBuyerBrokerDollar)}`;
  };

  const getCompanyName = (company) => {
    return company?.name ?? 'Company Not Specified';
  };

  const onEdit = (step) => (e) => {
    e.stopPropagation();
    onHandleJumpToStep(step);
  };

  const handleFileSelected = (uploadedFile, fileType) => {
    const data = { value: uploadedFile, field: typeMap[fileType] };
    onStateChanged(data);
  };

  const renderHeader = (step) => (
    <>
      <div className='d-flex align-items-center me-2'>
        <span className='step-number'>{step}</span>
        <span>{step === 8 ? 'Documents' : step === 9 ? 'Agent Details' : steps[step - 1].name}</span>
      </div>
      <div className='d-flex align-items-center justify-content-end'>
        {(!isCounter || (isCounter && step !== 1)) && !readOnly && step < 8 && (
          <Button className='d-flex' variant='light' onClick={onEdit(step)}>
            <MdEdit />
            <span className='ms-2'>{editLabel}</span>
          </Button>
        )}
        <Icon className='ms-3' name='chevron-down' />
      </div>
    </>
  );

  const renderService = (type, company) => {
    return (
      <li className='d-flex'>
        <span>{type} - &nbsp;</span>
        <div>
          <div>{getCompanyName(company)}</div>
          {company?.officerName && <div>{company.officerName}</div>}
          {company?.email && <div>{company.email}</div>}
          {company?.phone && <div>{formatPhoneNumber(company.phone)}</div>}
        </div>
      </li>
    );
  };

  const { primaryOwner, secondaryOwners } = listing ?? {};
  const {
    comment,
    primaryBuyer,
    secondaryBuyers,
    terms,
    contingencies,
    creditItems,
    costAllocations,
    otherTerms,
    buyerAddenda,
    proofOfFunds,
    loanApproval,
    letterToSeller,
    additionalOfferDocument,
    agent,
    flData,
  } = offer ?? {};

  const services = offer?.services ?? {};

  const isCalifornia = listing?.address?.state === 'CA';
  const isFlorida = listing?.address?.state === 'FL';

  return (
    <div>
      {!readOnly && (
        <>
          <div className='d-flex align-items-center flex-column flex-md-row'>
            <span className='ms-0 ms-sm-5' style={{ color: 'red' }}>
              {`Scroll down to review and take action.${
                isBuyer ? ' Contact your Agent to edit any details of this ' : ''
              }`}
            </span>
          </div>
        </>
      )}
      {comment && <div>{comment}</div>}
      <Collapse
        headerClassName='mt-3'
        header={Header}
        headerContent={renderHeader(1)}
        bodyClassName='border border-1'
        defaultCollapsed={!collapseAll}
      >
        <div className='mx-3 mb-3'>
          {listing && readOnly && (
            <>
              <Row className='mb-3'>
                <OfferFieldSummary title='Seller'>
                  {primaryOwner.user && (
                    <>
                      <div>Name: {`${primaryOwner.user.firstName} ${primaryOwner.user.lastName}`}</div>
                      <div>Cell: {formatPhoneNumber(primaryOwner.user.phone)}</div>
                      <div>Email: {primaryOwner.user.email}</div>
                    </>
                  )}
                </OfferFieldSummary>
                {primaryOwner.ownerType === 'INDIVIDUAL' &&
                  secondaryOwners?.map((owner) => (
                    <OfferFieldSummary title='CoSeller' key={owner.email}>
                      {owner.user && (
                        <>
                          <div>Name: {`${owner.user.firstName} ${owner.user.lastName}`}</div>
                          <div>Cell: {formatPhoneNumber(owner.user.phone)}</div>
                          <div>Email: {owner.user.email}</div>
                        </>
                      )}
                    </OfferFieldSummary>
                  ))}
              </Row>
            </>
          )}
          <Row className='mb-3'>
            <OfferFieldSummary title='Buyer'>
              {primaryBuyer.ownerType && primaryBuyer.ownerType !== 'INDIVIDUAL' && (
                <div>
                  It's a {primaryBuyer.ownerType.toLowerCase()}: {primaryBuyer.orgName}
                </div>
              )}
              {primaryBuyer.user && (
                <>
                  <div>
                    Name: {primaryBuyer.user.firstName} {primaryBuyer.user.lastName}
                  </div>
                  <div>Cell: {formatPhoneNumber(primaryBuyer.user.phone)}</div>
                  <div>Email: {primaryBuyer.user.email}</div>
                </>
              )}
            </OfferFieldSummary>
            {(primaryBuyer.ownerType === 'INDIVIDUAL' || primaryBuyer.ownerType === 'TRUST') &&
              secondaryBuyers?.map((buyer) => (
                <OfferFieldSummary title='CoBuyer' key={buyer.email}>
                  {buyer.user && (
                    <>
                      <div>
                        Name: {buyer.user.firstName} {buyer.user.lastName}
                      </div>
                      <div>Cell: {formatPhoneNumber(buyer.user.phone)}</div>
                      <div>Email: {buyer.user.email}</div>
                    </>
                  )}
                </OfferFieldSummary>
              ))}
          </Row>
        </div>
      </Collapse>
      <Collapse
        header={Header}
        headerContent={renderHeader(2)}
        bodyClassName='border border-1'
        defaultCollapsed={!collapseAll}
      >
        <div className='mx-3 mb-3'>
          <Row>
            <OfferFieldSummary sm={4} title='Offer'>
              {formatCurrency(terms.offerAmount, 0)}
            </OfferFieldSummary>
          </Row>
          <Row>
            <OfferFieldSummary sm={4} title='Payment'>
              <div>Purchase Method: {getPaymentMethod(terms)}</div>
              <div>Pre-approved: {terms.preapproved ? 'Yes' : 'No'}</div>
              <div>LTV/Loan: {formatCurrency(terms.loanAmountDollar)}</div>
              <div>Down Payment: {formatCurrency(terms.downPaymentDollar)}</div>
              <div>Earnest Money Deposit: {formatCurrency(terms.earnestDepositDollar)}</div>
              {!isCalifornia && terms.earnestDepositDays && (
                <div>Earnest Money Deposit Day(s) to deliver to escrow: {terms.earnestDepositDays}</div>
              )}
              <div>
                Additional Deposit:{' '}
                {!isNil(terms.increasedDepositDollar) ? formatCurrency(terms.increasedDepositDollar) : ''}
              </div>
              {terms.increasedDepositDays && (
                <div>Additional Deposit Day(s) to deliver to escrow: {terms.increasedDepositDays}</div>
              )}
            </OfferFieldSummary>
            <OfferFieldSummary sm={4} title='Purpose'>
              {terms.ownershipPurpose}
            </OfferFieldSummary>
            <OfferFieldSummary sm={4} title='Closing Date'>
              {terms?.useDaysToClose
                ? terms?.daysToClose + ' Days'
                : moment(terms?.exactClosingDate, 'YYYY-MM-DD').format('MM/DD/YYYY')}
            </OfferFieldSummary>
          </Row>
          {isCalifornia && (
            <Row>
              <OfferFieldSummary title='Additional Financial Terms'>
                {terms?.additionalFinanceTerms ?? 'None'}
              </OfferFieldSummary>
            </Row>
          )}
          <Row>
            <OfferFieldSummary title='Comments'>
              {terms?.termsComments ? terms.termsComments : 'None'}
            </OfferFieldSummary>
          </Row>
        </div>
      </Collapse>
      <Collapse
        header={Header}
        headerContent={renderHeader(3)}
        bodyClassName='border border-1'
        defaultCollapsed={!collapseAll}
      >
        <div className='mx-3 mb-3'>
          <Row>
            <OfferFieldSummary title='Sale of Home'>
              {isNil(contingencies?.contingentOnSale) && 'Not specified'}
              {!isNil(contingencies?.contingentOnSale) &&
                (contingencies?.contingentOnSale
                  ? contingencies?.propertyListedForSale
                    ? `On Market ${moment(contingencies?.listingDate).format('MM/DD/YYYY')}`
                    : 'Yes, not listed for sale'
                  : 'NO')}
            </OfferFieldSummary>
          </Row>
          {contingencies?.contingentOnSale && contingencies?.inEscrow && (
            <Row>
              <OfferFieldSummary sm={6} md={4} lg={3} title='Escrow Close Date'>
                {contingencies?.inEscrow
                  ? contingencies?.escrowClosedDate
                    ? moment(contingencies?.escrowClosedDate).format('MM/DD/YYYY')
                    : 'Not specified'
                  : 'N/A'}
              </OfferFieldSummary>
              <OfferFieldSummary sm={6} md={4} lg={3} title='Escrow Number'>
                {contingencies?.inEscrow ? contingencies?.escrowNumber : 'N/A'}
              </OfferFieldSummary>
              <OfferFieldSummary sm={6} md={4} lg={3} title='Escrow Company'>
                {contingencies?.inEscrow ? contingencies?.escrowCompany : 'N/A'}
              </OfferFieldSummary>
              <OfferFieldSummary sm={6} md={4} lg={3} title='Escrow Officer'>
                {contingencies?.inEscrow ? contingencies?.escrowOfficerName : 'N/A'}
              </OfferFieldSummary>
            </Row>
          )}
          {contingencies?.contingentOnSale && !contingencies?.inEscrow && (
            <Row>
              <OfferFieldSummary sm={6} md={4} lg={3} title='Escrow'>
                Not in escrow
              </OfferFieldSummary>
            </Row>
          )}
          <Row>
            <OfferFieldSummary sm={6} md={4} title='Loan Confirmation'>
              {isNil(contingencies?.contingentOnLoan)
                ? 'Not specified'
                : contingencies?.contingentOnLoan
                ? `${contingencies?.daysLoan} Days`
                : 'No'}
            </OfferFieldSummary>
            <OfferFieldSummary sm={6} md={4} title='Appraisal'>
              {isNil(contingencies?.contingentOnAppraisal)
                ? 'Not specified'
                : contingencies?.contingentOnAppraisal
                ? `${contingencies?.daysAppraisal} Days`
                : 'No'}
            </OfferFieldSummary>
            {isCalifornia && (
              <OfferFieldSummary sm={6} md={4} title='Minimal Acceptable appraisal amount'>
                {isNil(contingencies?.appraisalOtherAmount)
                  ? 'Not specified'
                  : formatCurrency(contingencies?.appraisalOtherAmount)}
              </OfferFieldSummary>
            )}
          </Row>
          <Row>
            <OfferFieldSummary sm={4} md={6} title='Inspection'>
              {isNil(contingencies?.contingentOnInspection)
                ? 'Not specified'
                : contingencies?.contingentOnInspection
                ? `${contingencies?.daysInspection} Days`
                : 'No'}
            </OfferFieldSummary>
            {isCalifornia && (
              <OfferFieldSummary sm={4} md={6} title='Days for Buyer to Review Seller Disclosures'>
                {contingencies?.daysRequiredOfBuyerReviewDisclosures
                  ? `${contingencies?.daysRequiredOfBuyerReviewDisclosures} Days`
                  : 'Not specified'}
              </OfferFieldSummary>
            )}
            {isCalifornia && (
              <OfferFieldSummary sm={4} md={6} title='Informational Access to Property'>
                {contingencies?.daysBuyerInfoAccess ? `${contingencies?.daysBuyerInfoAccess} Days` : 'Not specified'}
              </OfferFieldSummary>
            )}
            {isFlorida && (
              <OfferFieldSummary sm={4} md={6} title='Days for Buyer to Discover Flood zone and Elevation concerns'>
                {flData?.floodOrElevationDays ? `${flData?.floodOrElevationDays} Days` : 'Not specified'}
              </OfferFieldSummary>
            )}
          </Row>
          <Row>
            <OfferFieldSummary title='Comments'>
              {contingencies?.contingenciesComments ? contingencies.contingenciesComments : 'None'}
            </OfferFieldSummary>
          </Row>
        </div>
      </Collapse>
      <Collapse
        header={Header}
        headerContent={renderHeader(4)}
        bodyClassName='border border-1'
        defaultCollapsed={!collapseAll}
      >
        <div className='mx-3 mb-3'>
          <Row>
            <OfferFieldSummary sm={4} title="Seller pays the Buyer Broker's commission stated under form SPBB">
              {getSellerPaysBuyerBroker()}
            </OfferFieldSummary>
            <OfferFieldSummary sm={4} title='Closing Cost Credit'>
              {getClosingText()}
            </OfferFieldSummary>
            <OfferFieldSummary sm={4} title='Included Items'>
              {(!creditItems?.includedStateSpecifiedItems || creditItems?.includedStateSpecifiedItems.length === 0) &&
                (!creditItems?.includedItems || creditItems?.includedItems.length === 0) && (
                  <span className='text-muted'>No items included</span>
                )}
              {creditItems?.includedStateSpecifiedItems?.length > 0 && !isFlorida && (
                <>
                  {creditItems.includedStateSpecifiedItems.map((property, index) => (
                    <PropertyItem key={`${property.name}-${index}`} name={property.name} included />
                  ))}
                </>
              )}
              {creditItems?.includedItems?.length > 0 && (
                <>
                  {creditItems.includedItems.map((property, index) => (
                    <PropertyItem key={`${property.name}-${index}`} name={property.name} />
                  ))}
                </>
              )}
              {creditItems?.includedItems?.length === 0 && isFlorida && (
                <span className='text-muted'>No items included</span>
              )}
            </OfferFieldSummary>
            <OfferFieldSummary sm={4} title='Excluded Items'>
              {!creditItems?.excludedItems || creditItems?.excludedItems.length === 0 ? (
                <span className='text-muted'>No excluded items</span>
              ) : (
                <>
                  {creditItems.excludedItems.map((property, index) => (
                    <PropertyItem key={`${property.name}-${index}`} name={property.name} />
                  ))}
                </>
              )}
            </OfferFieldSummary>
          </Row>
          <Row>
            <OfferFieldSummary title='Comments'>
              {creditItems?.creditItemsComments ? creditItems?.creditItemsComments : 'None'}
            </OfferFieldSummary>
          </Row>
          {isCalifornia && (
            <Row>
              <OfferFieldSummary sm={6} title='Liquidated Damages Clause' size={20}>
                <Typography color={listing?.rpaBreachContract && offer.rpaBreachContract ? 'offer1-green' : 'danger'}>
                  {listing?.rpaBreachContract && offer.rpaBreachContract ? 'Yes' : 'No'}
                </Typography>
              </OfferFieldSummary>
              <OfferFieldSummary sm={6} title='Agreement to Arbitrate Clause'>
                <Typography
                  color={listing?.rpaDisputeResolution && offer.rpaDisputeResolution ? 'offer1-green' : 'danger'}
                >
                  {listing?.rpaDisputeResolution && offer.rpaDisputeResolution ? 'Yes' : 'No'}
                </Typography>
              </OfferFieldSummary>
            </Row>
          )}
        </div>
      </Collapse>
      <Collapse
        header={Header}
        headerContent={renderHeader(5)}
        bodyClassName='border border-1'
        defaultCollapsed={!collapseAll}
      >
        <div className='mx-3 mb-3'>
          <Row>
            {isFlorida && services.confirmationServices === 'BOTH' && (
              <OfferFieldSummary sm={4} md={6} title='Confirmation of Services'>
                Miami-Dade/Broward Provisional
              </OfferFieldSummary>
            )}
            {isFlorida && services.confirmationServices === 'SELLER' && (
              <OfferFieldSummary sm={4} md={6} title='Confirmation of Services'>
                Seller designates Closing Agent
              </OfferFieldSummary>
            )}
            {isFlorida && services.confirmationServices === 'BUYER' && (
              <OfferFieldSummary sm={4} md={6} title='Confirmation of Services'>
                Buyer designates Closing Agent
              </OfferFieldSummary>
            )}
          </Row>
          <Row>
            {servicePayerOptions.map((service) => (
              <OfferFieldSummary sm={6} key={service.payer} title={service.title}>
                <ul className='ms-n3'>
                  {services.warrantyIncluded && services.warrantyPayer === service.payer && (
                    <li>{`${services.warrantyCompany} - Not to exceed $${services.warrantyMaxCost}`}</li>
                  )}
                  {((isCalifornia && (services.titlePayer === service.payer || services.titlePayer === 'BOTH')) ||
                    (isFlorida &&
                      (services.confirmationServices === service.payer || services.confirmationServices === 'BOTH'))) &&
                    renderService(
                      'Title',
                      services.confirmationServices === 'SELLER' ? services.sellerTitleCompany : services.titleCompany
                    )}
                  {((isCalifornia && (services.escrowPayer === service.payer || services.escrowPayer === 'PAY_OWN')) ||
                    (isFlorida &&
                      (services.confirmationServices === service.payer || services.confirmationServices === 'BOTH'))) &&
                    renderService(
                      'Escrow',
                      services.confirmationServices === 'SELLER' ? services.sellerEscrowCompany : services.escrowCompany
                    )}
                  {isCalifornia &&
                    services.hazardPayer === service.payer &&
                    renderService(
                      'NHD',
                      services.hazardPayer === 'SELLER' ? services.sellerHazardCompany : services.hazardCompany
                    )}
                </ul>
              </OfferFieldSummary>
            ))}
          </Row>
          <Row>
            {isFlorida && services.confirmationServices === 'BOTH' && (
              <OfferFieldSummary
                sm={4}
                md={6}
                title='Max cost allocated to seller in the update of owner’s title policy'
              >
                ${flData?.titleContinuationCost || 200}
              </OfferFieldSummary>
            )}
            <OfferFieldSummary title='Comments'>{services?.servicesComments || 'None'}</OfferFieldSummary>
          </Row>
        </div>
      </Collapse>
      <Collapse
        header={Header}
        headerContent={renderHeader(6)}
        bodyClassName='border border-1'
        defaultCollapsed={!collapseAll}
      >
        <div className='m-3'>
          {isFlorida && (
            <Row>
              <OfferFieldSummary title='Cost Allocations'>
                <OfferCostAllocationTableFL />
              </OfferFieldSummary>
            </Row>
          )}
          <Row>
            <OfferFieldSummary title={isCalifornia ? 'Cost Allocations' : 'Additional Cost Allocations'}>
              <OfferCostAllocationTableCA costAllocations={costAllocations} />
            </OfferFieldSummary>
          </Row>
          <Row>
            {isFlorida && (
              <OfferFieldSummary title='Special Assessments'>
                {flData?.specialAssessments === 'SELLER'
                  ? 'Seller pays any public body special assessments that can be paid in full by the close of escrow'
                  : flData?.specialAssessments === 'BUYER'
                  ? 'Buyer pays any public body special assessments that can be paid in installments after COE'
                  : 'Not Specified'}
              </OfferFieldSummary>
            )}
            <OfferFieldSummary title='Comments'>
              {costAllocations?.costAllocationsComments ? costAllocations?.costAllocationsComments : 'None'}
            </OfferFieldSummary>
          </Row>
        </div>
      </Collapse>
      <Collapse
        header={Header}
        headerContent={renderHeader(7)}
        bodyClassName='border border-1'
        defaultCollapsed={!collapseAll}
      >
        <div className='mx-3 mb-3'>
          <Row>
            <OfferFieldSummary sm={12} md={6} title='Buyer addenda that will be part of your offer'>
              <CheckBoxContainer>
                {(isFlorida ? buyerAddendaItemsFL : buyerAddendaItemsCA).map(
                  (item) =>
                    buyerAddenda?.[item.name] && (
                      <React.Fragment key={`buyer-addenda-${item.name}`}>
                        <CheckBox name={item.name} checked={buyerAddenda?.[item.name]} readOnly>
                          {item.label}
                          {item.name === 'other' && <span>({buyerAddenda?.['otherDescription']})</span>}
                        </CheckBox>
                        {buyerAddenda?.[`${item.name}File`] && (
                          <PropertyItem
                            included
                            name={buyerAddenda?.[`${item.name}File`].name}
                            onClick={() => URLUtils.openDocument(buyerAddenda?.[`${item.name}File`].url)}
                          />
                        )}
                        {buyerAddenda?.[item.name] && !buyerAddenda?.[`${item.name}File`] && (
                          <span className='text-danger'>***Form NEEDED</span>
                        )}
                      </React.Fragment>
                    )
                )}
              </CheckBoxContainer>
            </OfferFieldSummary>
            <OfferFieldSummary sm={12} md={6} title='Seller Added Addenda'>
              <OfferSellerAddenda
                addendaItems={isFlorida ? propertyAddendaItemsFL : propertyAddendaItemsCA}
                addenda={listing?.propertyAddenda}
                remainInPropertyAnswer={listing?.remainInPropertyAnswer}
                remainInPropertyFile={listing?.remainInPropertyFile}
                customAddendum={listing?.customAddendum}
                customAddendumFile={listing?.customAddendumFile}
              />
            </OfferFieldSummary>
            <OfferFieldSummary sm={12} md={6} title='Other Terms'>
              {otherTerms || 'None'}
            </OfferFieldSummary>
          </Row>
        </div>
      </Collapse>
      <Collapse
        header={Header}
        headerContent={renderHeader(8)}
        bodyClassName='border border-1'
        defaultCollapsed={!collapseAll}
      >
        <div className='m-3'>
          {!isNil(terms.loan) && (
            <>
              <div className='mt-4' id='proofOfFunds'>
                <FileSelect
                  type='PROOF_FUNDS'
                  title='Proof of Funds'
                  file={proofOfFunds}
                  onFileSelected={handleFileSelected}
                  deleteFile={(fileType) => handleFileSelected(null, fileType)}
                  readOnly={readOnly || !isBuyerAgent}
                />
                {!proofOfFunds && errors?.proofOfFunds && (
                  <Typography color='danger'>This document is required.</Typography>
                )}
              </div>
            </>
          )}
          {terms.loan === true && (
            <div className='mt-4' id='loanApproval'>
              <FileSelect
                type='LOAN_APPROVAL'
                title='Loan Approval'
                file={loanApproval}
                onFileSelected={handleFileSelected}
                deleteFile={(fileType) => handleFileSelected(null, fileType)}
                readOnly={readOnly || !isBuyerAgent}
              />
              {!loanApproval && errors?.loanApproval && (
                <Typography color='danger'>This document is required.</Typography>
              )}
            </div>
          )}
          <div className='mt-4'>
            <FileSelect
              type='SELLER_LETTER'
              title='Letter to Seller(optional)'
              file={letterToSeller}
              onFileSelected={handleFileSelected}
              deleteFile={(fileType) => handleFileSelected(null, fileType)}
              readOnly={readOnly || !isBuyerAgent}
            />
          </div>
          <div className='mt-4'>
            <FileSelect
              type='OTHER'
              title='Additional document(optional)'
              file={additionalOfferDocument}
              onFileSelected={handleFileSelected}
              deleteFile={(fileType) => handleFileSelected(null, fileType)}
              readOnly={readOnly || !isBuyerAgent}
            />
          </div>
        </div>
      </Collapse>
      <Collapse
        header={Header}
        headerContent={renderHeader(9)}
        bodyClassName='border border-1'
        defaultCollapsed={!collapseAll}
      >
        <div className='m-3'>
          {agent && (
            <>
              {listing && (
                <Row className='mb-3'>
                  <OfferFieldSummary title='Listing Agent'>
                    <>
                      <div>Name: {`${listing.listingAgent.firstName} ${listing.listingAgent.lastName}`}</div>
                      <div>Cell: {formatPhoneNumber(listing.listingAgent.phone)}</div>
                      <div>Email: {listing.listingAgent.email}</div>
                      <div>DRE: {listing.listingAgent.agent.licenseNumber || 'N/A'}</div>
                      <div>Brokerage Name: {listing.listingAgent.agent?.brokerage?.name || 'N/A'}</div>
                      <div>Broker DRE: {listing.listingAgent.agent?.brokerage?.licenseNumber || 'N/A'}</div>
                    </>
                  </OfferFieldSummary>
                </Row>
              )}
              <Row className='mb-3'>
                <OfferFieldSummary title='Buyer Agent'>
                  <>
                    <div>Name: {`${agent.firstName} ${agent.lastName}`}</div>
                    <div>Cell: {formatPhoneNumber(agent.phone)}</div>
                    <div>Email: {agent.email}</div>
                    <div>DRE: {agent.agent.licenseNumber || 'N/A'}</div>
                    <div>Brokerage Name: {agent.agent?.brokerage?.name || 'N/A'}</div>
                    <div>Broker DRE: {agent.agent?.brokerage?.licenseNumber || 'N/A'}</div>
                  </>
                </OfferFieldSummary>
              </Row>
            </>
          )}
        </div>
      </Collapse>
    </div>
  );
}

OfferReview.propType = {
  offer: PropTypes.object.isRequired,
  listing: PropTypes.object,
  steps: PropTypes.array.isRequired,
  onStateChanged: PropTypes.func,
  onHandleJumpToStep: PropTypes.func,
  isBuyerAgent: PropTypes.bool,
  isBuyer: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default OfferReview;
