import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { FormField, PhoneField, EmailField, CheckBox } from 'components';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { apiCall } from 'utils/apiCall';
import { joinString } from 'utils';
import { nanoid } from 'nanoid';

const CompanyInput = ({
  id,
  type,
  value,
  userNameOnly,
  repOnly,
  errors,
  sameAs,
  sameAsLabel,
  disabled,
  onStateChanged,
}) => {
  const [asyncTypeId, setAsyncTypeId] = useState(nanoid());
  const [name, setName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [sameAsChecked, setSameAsChecked] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const handleSearch = async (query) => {
    setName(query);
    setIsLoading(true);

    try {
      const res = await apiCall('/companies', 'GET', { companyName: query, companyType: type });

      setOptions(res.data);
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  };

  const filterBy = () => true;

  useEffect(() => {
    onStateChanged({
      field: id,
      value: {
        address: {
          addressLine1: `${address || ''}`,
          addressLine2: null,
          city: null,
          id: null,
          latitude: null,
          longitude: null,
          state: null,
          zip: null,
          zipPlus: null,
        },
        email,
        id: null,
        name,
        officerName: `${firstName || ''}${lastName ? ' ' : ''}${lastName || ''}`,
        phone,
        type,
      },
    });
    //eslint-disable-next-line
  }, [name, firstName, lastName, email, phone, address]);

  useEffect(() => {
    setName(value?.name);
    setFirstName(value?.officerName?.split(' ')[0] || '');
    setLastName(value?.officerName?.split(' ')[1] || '');
    setEmail(value?.email);
    setPhone(value?.phone);
    setAddress(value?.address?.addressLine1);
    setSameAsChecked(
      sameAs &&
        value &&
        value.name === sameAs.name &&
        value.officerName === sameAs.officerName &&
        value.email === sameAs.email &&
        value.phone === sameAs.phone &&
        value.address &&
        value.address.addressLine1 === sameAs.address.addressLine1
    );
    //eslint-disable-next-line
  }, []);

  const handleSameAs = (event) => {
    const checked = event.target.checked;
    setSameAsChecked(checked);
  };

  const handleChangeCompany = (event) => {
    const [company] = event;
    if (!company) {
      return;
    }
    setName(company.name);
    const [firstName, lastName] = company.officerName?.split(' ').filter((name) => name);
    setFirstName(firstName);
    setLastName(lastName);
    setEmail(company.email);
    setPhone(company.phone);
    const address = joinString(
      [
        company?.address.addressLine1,
        company?.address.addressLine2,
        company?.address.city,
        company?.address.state,
        company?.address.zip,
      ],
      ', '
    );
    setAddress(address);
  };
  useEffect(() => {
    if (sameAs && sameAsChecked) {
      setName(sameAs.name);
      setFirstName(sameAs.officerName?.split(' ')[0] || '');
      setLastName(sameAs.officerName?.split(' ')[1] || '');
      setEmail(sameAs.email);
      setPhone(sameAs.phone);
      setAddress(sameAs.address?.addressLine1);
      setTimeout(() => {
        setAsyncTypeId(nanoid());
      });
      // setAddress(sameAs);
    }
  }, [sameAs, sameAsChecked]);

  return (
    <>
      <Row>
        {sameAs !== undefined && (
          <CheckBox className='ms-3' checked={sameAsChecked} onChange={handleSameAs} disabled={disabled}>
            {sameAsLabel}
          </CheckBox>
        )}
        <Col sm={6}>
          <Form.Group>
            <Form.Label>Company Name*</Form.Label>
            <AsyncTypeahead
              key={asyncTypeId}
              filterBy={filterBy}
              id={`${id}Name`}
              isLoading={isLoading}
              labelKey='name'
              minLength={1}
              delay={300}
              disabled={disabled}
              onSearch={handleSearch}
              options={options}
              defaultInputValue={value?.name}
              placeholder='Search for a company...'
              promptText='Type to search for a company'
              onChange={handleChangeCompany}
              useCache={false}
              renderMenuItemChildren={(option) => (
                <div>
                  <strong>{option.label}</strong>
                  <div>
                    <small>Officer Name: {option.officerName}</small>
                  </div>
                  <div>
                    <small>Email: {option.email}</small>
                  </div>
                  <div>
                    <small>Phone: {option.phone}</small>
                  </div>
                  <div>
                    <small>
                      Address:{' '}
                      {joinString(
                        [
                          option?.address.addressLine1,
                          option?.address.addressLine2,
                          option?.address.city,
                          option?.address.state,
                          option?.address.zip,
                        ],
                        ', '
                      )}
                    </small>
                  </div>
                </div>
              )}
            />
            {errors?.[`${id}Name`] && (
              <Form.Text className='error form-hint fw-bold text-end m-0 mb-2'>{errors?.[`${id}Name`]}</Form.Text>
            )}
          </Form.Group>
        </Col>
      </Row>
      {!userNameOnly && (
        <>
          <Row>
            <Col sm={6}>
              <FormField
                fieldId={`${id}-firstName`}
                onStateChanged={(event) => setFirstName(event.value)}
                initialValue={firstName}
                editable={!disabled}
                label={repOnly ? 'Rep Firstname' : 'Rep/Officer Firstname'}
              />
            </Col>
            <Col sm={6}>
              <FormField
                fieldId={`${id}-lastName`}
                onStateChanged={(event) => setLastName(event.value)}
                initialValue={lastName}
                editable={!disabled}
                label={repOnly ? 'Rep Lastname' : 'Rep/Officer Lastname'}
              />
            </Col>
          </Row>
          <Row>
            <FormField
              fieldId={`${id}-address`}
              onStateChanged={(event) => setAddress(event.value)}
              initialValue={address}
              editable={!disabled}
              label='Address'
            />
          </Row>
          <Row>
            <Col sm={6}>
              <EmailField
                fieldId={`${id}-email`}
                label='Email'
                initialValue={email}
                editable={!disabled}
                onStateChanged={(event) => setEmail(event.value)}
              />
            </Col>
            <Col sm={6}>
              <PhoneField
                fieldId={`${id}-phone`}
                onChange={(event) => setPhone(event.value)}
                value={phone}
                editable={!disabled}
                label={repOnly ? 'Rep Mobile' : 'Rep/Officer Mobile'}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default CompanyInput;
