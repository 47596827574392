export const initialOffer = {
  id: undefined,
  listingId: undefined,
  state: 'DRAFT',
  approvePayment: null,
  requestBuyerApproval: null,
  terms: {
    loan: null,
    daysToClose: '',
    ownershipPurpose: null,
    loanType: null,
    preapproved: null,
    termsComments: '',
    earnestDepositPercentage: 0,
    earnestDepositDollar: '',
    earnestDepositDays: null,
    downPaymentPercentage: '',
    downPaymentDollar: '',
    loanAmountPercentage: '',
    loanAmountDollar: '',
    offerAmount: '',
    increasedDepositPercentage: 0,
    increasedDepositDollar: '',
    increasedDepositDays: null,
  },
  contingencies: {
    contingentOnSale: null,
    inEscrow: null,
    escrowClosedDate: new Date(),
    daysToClose: '',
    propertyListedForSale: null,
    listingDate: new Date(),
    contingentOnLoan: null,
    daysLoan: '',
    contingentOnAppraisal: null,
    daysAppraisal: '',
    contingentOnInspection: null,
    daysInspection: '',
    contingenciesComments: '',
    daysRequiredOfBuyerReviewDisclosures: null,
    daysBuyerInfoAccess: null,
  },
  creditItems: {
    sellerPaysBuyerBroker: null,
    sellerPaysBuyerBrokerDollar: '',
    sellerPaysBuyerBrokerPercentage: '',
    requestSeller: null,
    closingCostCreditDollar: '',
    closingCostCreditPercentage: '',
    additionalIncludedPersonalProperty: '',
    additionalExcludedPersonalProperty: '',
    itemsComments: '',
    includedItems: [],
    excludedItems: [],
  },
  costAllocations: {
    costAllocationsType: 'STANDARD',
    smokeAlarmInstalls: 'BUYER',
    costInspectionsReports: 'BUYER',
    govRetrofitStandards: 'BUYER',
    cityTransferTax: 'BUYER',
    countyTransferTax: 'BUYER',
    privateTransferTax: 'BUYER',
    hoaTransferTax: 'BUYER',
    hoaDocPrepFee: 'SELLER',
    additionalItems: [],
    costAllocationsComments: '',
  },
  services: {
    titlePayer: null,
    titleCompany: null,
    escrowPayer: null,
    escrowCompany: null,
    warrantyMaxCost: null,
    warrantyPayer: null,
    warrantyCompany: null,
    hazardPayer: null,
    hazardCompany: null,
    servicesComments: '',
    confirmationServices: null,
  },
  primaryBuyer: {
    user: null,
    ownerType: null,
    certifyBuyer: null,
  },
  secondaryBuyers: [],
  otherTerms: null,
  buyerAddenda: {
    buyer1031IntentToExchange: null,
    noContingencies: null,
    saleOfBuyerHomeContingency: null,
  },
  flData: {},
};

export const responsibilities = [
  {
    field: 'hoaDocuments',
    description: 'HOA Documents',
  },
  {
    field: 'smokeAlarmInstalls',
    description: 'Responsible for smoke and CO device installation and water heater bracing',
  },
  {
    field: 'costInspectionsReports',
    description: 'Responsible for cost of compliance of mandatory government inspections/reports',
  },
  {
    field: 'govRetrofitStandards',
    description: 'Responsible for minimum mandatory government retrofit standards',
  },
  {
    field: 'countyTransferTax',
    description: 'Shall pay for County transfer tax',
  },
  {
    field: 'cityTransferTax',
    description: 'Shall pay for City transfer tax',
  },
  {
    field: 'hoaTransferTax',
    description: 'Shall pay for HOA transfer tax',
  },
  {
    field: 'hoaDocPrepFee',
    description: 'Shall pay for preperation of HOA documents other than those required by CC4525',
  },
  {
    field: 'privateTransferTax',
    description: 'Shall pay for private transfer tax',
  },
];
