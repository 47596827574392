export const initListing = {
  address: {
    addressLine1: '',
    addressLine2: '',
    city: '',
    county: '',
    state: null,
    zip: '',
  },
  propertyType: null,
  squareFeet: null,
  numberBedrooms: null,
  numberBaths: null,
  description: '',
  primaryImageUrl: null,
  customListingUrl: '',
  ownerType: null,
  primaryOwner: {
    user: {
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
    },
    address: {
      addressLine1: null,
      addressLine2: null,
      city: null,
      state: null,
      zip: null,
    },
    ownerType: null,
    signerAffidavit: null,
    articlesOfIncorporation: null,
    partnershipAgreement: null,
    orgName: null,
  },
  escrowCompany: {},
  titleCompany: {},
  hazardCompany: {},
  includedItems: [],
  excludedItems: [],
  lienItems: [],
  hasIncludedItems: null,
  hasExcludedItems: null,
  hasLienItems: null,
  price: 0,
  state: 'DRAFT',
  offerDetails: {
    offerType: 'GLOBAL',
    instantAuction: {
      auctionTimeDays: -1,
      auctionTimeStart: null,
      closeTimeDays: -1,
      closeTimeStart: null,
      earnestMoney: null,
      loan: null,
      loanDays: -1,
      appraisal: null,
      appraisalDays: -1,
      inspection: null,
      inspectionDays: -1,
      smokeDevice: null,
      inspectionMandatory: null,
      retrofitMandatory: null,
      countyTransfer: null,
      cityTransfer: null,
      HOATransfer: null,
      privateTransfer: null,
      HOADocuments: null,
      naturalHazard: null,
      homeWarranty: null,
      homeWarrantyResponsibility: null,
      homeWarrantyMaxAmount: null,
      ownerTitleInsurance: null,
      escrow: null,
      closingCostCredit: null,
      closingCostPercentage: null,
      sellerPossession: null,
      sellerPossessionDays: -1,
      sellerPossessionFee: null,
      sellerPossessionFeeExplain: '',
      notMovingOutPriorToClose: null,
      moveOutDaysPriorToClose: -1,
    },
  },
  listingReports: [],
  rpaBreachContract: null,
  rpaDisputeResolution: null,
  rpaAddenda: {
    propertyAddenda: {
      probateAgreement: null,
      probateAgreementFile: null,
      manufacturedHome: null,
      manufacturedHomeFile: null,
      tenancyInCommon: null,
      tenancyInCommonFile: null,
      stockCooperative: null,
      stockCooperativeFile: null,
      tenantOccupied: null,
      tenantOccupiedFile: null,
      shortSale: null,
      shortSaleFile: null,
      septicWellPropertyMonumentPropane: null,
      septicWellPropertyMonumentPropaneFile: null,
      seller1031IntentToExchange: null,
      seller1031IntentToExchangeFile: null,
      mixedUse: null,
    },
    remainInProperty: null,
    customAddendum: null,
    customAddendumFile: null,
    advisories: {
      stateWideBuyerAndSeller: null,
      wildfireDisaster: null,
      reo: null,
      shortSale: null,
    },
  },
};
