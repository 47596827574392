import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { PopoverTooltip, RadioButtons, Tooltip, Typography, Divider } from 'components';
import { RpaItem29Tooltip, RpaItem31Tooltip } from 'containers/CreateOfferPage/rpaTooltip';
import { yesNoOptions } from 'constants/optionConstants';

const RadioRow = ({ items, title, name, value, onRpaItemsChanged, tooltip, error }) => {
  const handleRadioSelection = (event) => {
    const { name, value } = event.target;
    const valueMap = {
      yes: true,
      no: false,
    };
    onRpaItemsChanged(name, valueMap[value]);
  };

  return (
    <Row className='mt-2 align-items-center'>
      <Col sm={8}>
        {title}
        {tooltip && <Tooltip placement='right' overlay={tooltip} trigger='click' />}
      </Col>
      <Col sm={2}>
        <RadioButtons
          items={items}
          groupName={name}
          selectedOption={value !== null ? (value === true ? 'yes' : 'no') : 'n/a'}
          error={error}
          onStateChanged={handleRadioSelection}
        />
      </Col>
    </Row>
  );
};

const RPAClauses = ({
  listingState,
  rpaBreachContract,
  rpaDisputeResolution,
  proofOfFundsRequired,
  tenantOccupiedPostCoe,
  sellerFinancingAvailable,
  assignNo,
  assignLiability,
  errors,
  onStateChanged,
}) => {
  return (
    <>
      <Typography as='h6' size={24}>
        Agreement Clauses
        {listingState === 'CA' && (
          <PopoverTooltip
            id='residential-purchase-agreement-clauses-tooltip'
            labelId='tooltip.residentialPurchaseAgreementClauses.label'
            contentId='tooltip.residentialPurchaseAgreementClauses.description'
          />
        )}
      </Typography>
      {listingState === 'CA' && (
        <>
          <RadioRow
            items={yesNoOptions}
            title={`1. Require item 29 in CA contract "Liquidated Damages Clause" to be required for any offer.*`}
            name='rpaBreachContract'
            value={rpaBreachContract}
            error={errors?.rpaBreachContract}
            onRpaItemsChanged={onStateChanged}
            tooltip={RpaItem29Tooltip}
          />
          <RadioRow
            items={yesNoOptions}
            title={`2. Require item 31 in the CA contract " Arbitration of Disputes" to be required for any offer.*`}
            name='rpaDisputeResolution'
            value={rpaDisputeResolution}
            error={errors?.rpaDisputeResolution}
            onRpaItemsChanged={onStateChanged}
            tooltip={RpaItem31Tooltip}
          />
        </>
      )}
      {listingState === 'FL' && (
        <>
          <RadioRow
            items={yesNoOptions}
            title={`1. Will the property be subject to lease or occupancy from a 3rd party after the COE?`}
            name='tenantOccupiedPostCoe'
            value={tenantOccupiedPostCoe}
            error={errors?.tenantOccupiedPostCoe}
            onRpaItemsChanged={onStateChanged}
          />
          <RadioRow
            items={yesNoOptions}
            title={`2. May this contract be assigned?`}
            name='assignNo'
            value={assignNo}
            error={errors?.assignNo}
            onRpaItemsChanged={onStateChanged}
          />
          {assignNo && (
            <RadioButtons
              items={[
                { name: 'Assigned with NO further liability', value: 'ASSIGN_YES_NO_LIABILITY' },
                { name: 'Assigned with liability', value: 'ASSIGN_YES_LIABILITY' },
              ]}
              selectedOption={assignLiability}
              groupName='assignLiability'
              error={errors?.assignLiability}
              onStateChanged={(e) => onStateChanged(e.target.name, e.target.value)}
            />
          )}
        </>
      )}
      <Divider className='mx-n2 mx-md-n4 my-3' />
      <Typography className='mt-3' as='h6' size={24}>
        Listing Preferences
      </Typography>
      <RadioRow
        items={yesNoOptions}
        title={`3. Require proof of funds and pre-approval for all offers?`}
        name='proofOfFundsRequired'
        value={proofOfFundsRequired}
        error={errors?.proofOfFundsRequired}
        onRpaItemsChanged={onStateChanged}
      />
      {listingState === 'FL' && (
        <RadioRow
          items={yesNoOptions}
          title={`4. If desired, could a buyer request seller financing on their offer? (please verify with the seller prior to answering)`}
          name='sellerFinancingAvailable'
          value={sellerFinancingAvailable}
          error={errors?.sellerFinancingAvailable}
          onRpaItemsChanged={onStateChanged}
        />
      )}
    </>
  );
};

export default RPAClauses;
