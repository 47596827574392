import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import { useEnhancedReducer, useAuthUser, useScrollToTop } from 'hooks';
import { CreateHeader, CreateBottomNavBar, CreateTopNavBar, Timeline, ListingInfo, Divider } from 'components';
import { FaRegSave } from 'react-icons/fa';
import routeConstants from '../App/routeConstants';
import OfferTerms from './offerTerms';
import OfferContingencies from './offerContingencies';
import { initialOffer } from './offerObj';
import OfferCreditItems from './offerCreditItems';
import OfferCostAllocationsCA from './offerCostAllocationsCA';
import OfferCostAllocationsFL from './offerCostAllocationsFL';
import OfferServicesCA from './offerServicesCA';
import OfferServicesFL from './offerServicesFL';
import OfferContact from './offerContact';
import OfferReview from './offerReview';
import { CreateOfferAsyncActions } from 'store/actions/createOffer';
import { BuyersAsyncActions } from 'store/actions/buyers';
import { ListingsAsyncActions } from 'store/actions/listings';
import { getCreateOffer } from 'store/selectors/createOffer';
import { getListing } from 'store/selectors/listings';
import OtherTermsAddenda from './offerOtherTermsAddenda';
import actionType from './actionTypes';
import routes from 'containers/App/routeConstants';
import { URLUtils } from 'utils';
import { ContinueButton, OfferAgentPending } from './createOfferPage.styled';
import { getOwnershipTypes, getLoanTypes } from 'store/selectors/app';
import { buyerAddendaItemsCA, buyerAddendaItemsFL, offerSteps } from 'constants/offerConstants';
import moment from 'moment';
import { isNil } from 'lodash';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

function offerReducer(state, action) {
  switch (action.type) {
    case actionType.OFFER_INIT:
      return action.payload;

    case actionType.OFFER_UPDATE_TERM:
      return {
        ...state,
        terms: action.payload,
      };

    case actionType.OFFER_UPDATE_CONTINGENCIES:
      return {
        ...state,
        contingencies: {
          ...state.contingencies,
          [action.payload.field]: action.payload.value,
        },
      };

    case actionType.OFFER_UPDATE_CREDIT_ITEMS:
      return {
        ...state,
        creditItems: action.payload,
      };

    case actionType.OFFER_UPDATE_COST_ALLOCATIONS:
      return {
        ...state,
        costAllocations: {
          ...state.costAllocations,
          [action.payload.field]: action.payload.value,
        },
      };

    case actionType.OFFER_UPDATE_SERVICES:
      return {
        ...state,
        services: {
          ...state.services,
          [action.payload.field]: action.payload.value,
        },
      };

    case actionType.OFFER_UPDATE_CONTACT_INFO:
      return {
        ...state,
        primaryBuyer: {
          ...state.primaryBuyer,
          [action.payload.field]: action.payload.value,
          ...(action.payload.field === 'ownerType' && action.payload.value === 'INDIVIDUAL' && { orgName: '' }),
        },
        ...(action.payload.field === 'ownerType' &&
          action.payload.value !== 'INDIVIDUAL' &&
          action.payload.value !== 'TRUST' && { secondaryBuyers: [] }),
      };

    case actionType.OFFER_UPDATE_OTHER_TERMS_ADDENDA:
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };

    case actionType.OFFER_UPDATE_REVIEW:
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };

    case actionType.OFFER_UPDATE_SECONDARY_BUYERS:
      return {
        ...state,
        secondaryBuyers: action.payload.value,
      };

    case actionType.OFFER_UPDATE_STATE_DATA: {
      const listingState = action.listingState.toLowerCase();
      return {
        ...state,
        [`${listingState}Data`]: {
          ...(state[`${listingState}Data`] || {}),
          [action.payload.field]: action.payload.value,
        },
      };
    }
    default:
      return state;
  }
}

const END_STEP = 8;

function CreateOfferPage({ existingOffer, existingListing, isCounter = false, onCounter }) {
  const { user } = useAuthUser();
  const [listing, setListing] = useState();
  const reduxDispatch = useDispatch();
  const fetchedListing = useSelector(getListing);
  const { isSuccess: isFetchListingSuccess } = useSelector(ListingsAsyncActions.FetchListing.StatusSelector());
  const { isSuccess: isCreatedSuccess, error: createError } = useSelector(
    CreateOfferAsyncActions.CreateOffer.StatusSelector()
  );
  const createdOffer = useSelector(getCreateOffer);
  const { isSuccess: isUpdatedSuccess, error: updateError } = useSelector(
    CreateOfferAsyncActions.UpdateOffer.StatusSelector()
  );
  const { isSuccess: isUpdatedStateSuccess, error: updateStateError } = useSelector(
    CreateOfferAsyncActions.UpdateState.StatusSelector()
  );
  const ownershipPurpose = useSelector(getOwnershipTypes);
  const loanTypes = useSelector(getLoanTypes);
  const history = useHistory();
  const [offer, dispatch, getOffer] = useEnhancedReducer(offerReducer, initialOffer);
  const { isAgent, isBuyerAgent, isOfferBuyer } = useAuthUser({ offer });
  const [showSavedNow, setShowSavedNow] = useState(false);
  const [stepNumber, setStepNumber] = useState(1);
  const [isReview, setIsReview] = useState(false);
  const locationState = useLocation().state;
  const { offerCount, highestOffer } = locationState ?? {};
  const [errors, setErrors] = useState({
    primaryBuyer: {},
    secondaryBuyers: {},
    terms: {},
    contingencies: {},
    creditItems: {},
    costAllocations: {},
    services: {},
    otherTerms: {},
    review: {},
  });
  const [dirty, setDirty] = useState(false);

  useScrollToTop([stepNumber]);

  const checkLicense = useCallback(
    (newListing) => {
      if (!existingOffer && newListing?.address?.state !== user?.agent?.licenseState) {
        Swal.fire({
          icon: 'warning',
          text: 'Sorry, you must be licensed in the same State as the property.',
          confirmButtonText: 'OK',
        }).then(() => {
          history.push('/home');
        });
      }
    },
    [user, history, existingOffer]
  );

  useEffect(() => {
    if (locationState?.listing) {
      setListing(locationState.listing);
      checkLicense(locationState.listing);
    } else if (existingListing) {
      setListing(existingListing);
      checkLicense(existingListing);
    } else if (existingOffer) {
      reduxDispatch(ListingsAsyncActions.FetchListing.Actions.REQUEST(existingOffer.listingId));
    } else {
      history.push('/home');
    }

    return () => {
      if (!locationState?.listing && !existingListing && existingOffer) {
        reduxDispatch(ListingsAsyncActions.FetchListing.Actions.RESET());
      }
    };
  }, [reduxDispatch, locationState, existingOffer, existingListing, history, checkLicense]);

  useEffect(() => {
    if (isFetchListingSuccess) {
      setListing(fetchedListing);
      checkLicense(fetchedListing);
    }
  }, [reduxDispatch, fetchedListing, isFetchListingSuccess, checkLicense]);

  useEffect(() => {
    return () => {
      reduxDispatch(CreateOfferAsyncActions.FetchPreFilledOffer.Actions.RESET());
      reduxDispatch(CreateOfferAsyncActions.CreateOffer.Actions.RESET());
      reduxDispatch(CreateOfferAsyncActions.UpdateOffer.Actions.RESET());
      reduxDispatch(CreateOfferAsyncActions.DeleteOffer.Actions.RESET());
      reduxDispatch(CreateOfferAsyncActions.UpdateState.Actions.RESET());
    };
  }, [reduxDispatch]);

  useEffect(() => {
    if (listing && !existingOffer) {
      reduxDispatch(CreateOfferAsyncActions.FetchPreFilledOffer.Actions.REQUEST(listing.id, locationState?.profileId));
    }
    // eslint-disable-next-line
  }, [listing, reduxDispatch]);

  const setOffer = useCallback(
    (value) => {
      dispatch({
        type: actionType.OFFER_INIT,
        payload: value,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (createdOffer) {
      setOffer(createdOffer);
    }
  }, [setOffer, createdOffer]);

  useEffect(() => {
    if (isCreatedSuccess || isUpdatedSuccess) {
      setShowSavedNow(true);
    }
  }, [isCreatedSuccess, isUpdatedSuccess]);

  useEffect(() => {
    if (isUpdatedStateSuccess) {
      MySwal.fire({
        title: 'Offer has been updated',
        html: (
          <>
            <p>
              Your Offer has been updated and transitioned. Click below to be taken to your dashboard and await next
              steps.
            </p>
            <Timeline
              steps={['Create Offer (Agent)', 'Approve Offer (Buyer)', 'Send to Seller (Offer1)']}
              step={createdOffer.state === 'PENDING_SELLER_AGENT_RESPONSE' ? 2 : 0}
            />
          </>
        ),
        confirmButtonText: 'Continue',
        allowOutsideClick: false,
      }).then(() => {
        history.push(routeConstants.OFFERS_PATH);
      });
    }
  }, [isUpdatedStateSuccess, createdOffer, history]);

  useEffect(() => {
    if (existingOffer) {
      setOffer(existingOffer);
      setStepNumber(END_STEP);
      setIsReview(true);
    }
  }, [setOffer, existingOffer]);

  const isCalifornia = listing?.address?.state === 'CA';
  const isFlorida = listing?.address?.state === 'FL';
  const stateData = offer[`${listing?.address?.state?.toLowerCase()}Data`] || {};

  const saveBuyerProfile = () => {
    const profileToSave = {
      offerId: offer.id,
      name: listing.address.addressLine1,
    };
    reduxDispatch(BuyersAsyncActions.CreateBuyerProfile.Actions.REQUEST(profileToSave));
  };

  const saveOffer = (offer) => {
    setShowSavedNow(false);
    // Prefill default values
    const offerToSave = {
      ...offer,
      contingencies: {
        ...offer.contingencies,
        ...(isCalifornia
          ? {
              daysRequiredOfBuyerReviewDisclosures: offer.contingencies.daysRequiredOfBuyerReviewDisclosures || 17,
              daysBuyerInfoAccess: offer.contingencies.daysBuyerInfoAccess || 17,
            }
          : {}),
      },
    };

    if (!isCounter) {
      if (isNil(offer.id)) {
        reduxDispatch(CreateOfferAsyncActions.CreateOffer.Actions.REQUEST(offerToSave));
      } else {
        reduxDispatch(CreateOfferAsyncActions.UpdateOffer.Actions.REQUEST(offerToSave));
      }
    }
  };

  const showSubmitModal = async (status) => {
    if (checkRequirements(8)) {
      return;
    }
    if (status === 'PENDING_BUYER_APPROVAL' || status === 'LIVE_EDIT_BUYER') {
      const { value: accept } = await Swal.fire({
        title: 'Confirm Send to Buyer for Approval',
        text: 'Once the Buyer has reviewed the offer they can either approve or reject. If approved the offer will be sent directly to the seller. If rejected you will be notified to make corrections.',
        input: 'checkbox',
        inputValue: 0,
        inputPlaceholder: 'I attest that the information I have provided is true and accurate.',
        confirmButtonText: 'Continue',
        inputValidator: (result) => {
          return !result && 'You need to agree';
        },
      });

      if (accept && offer.state === 'DRAFT' && status === 'PENDING_BUYER_APPROVAL') {
        const { value: buyerProfile } = await Swal.fire({
          title: 'Save Buyer Profile',
          text: 'Do you want to save this offer as a buyer profile to save time creating a future offer?',
          input: 'radio',
          inputOptions: { yes: 'Yes', no: 'No' },
          confirmButtonText: 'Continue',
          inputValidator: (result) => {
            return !result && 'You need to select yes or no';
          },
        });

        if (buyerProfile === 'yes') {
          saveBuyerProfile();
        }
      }

      if (accept) {
        updateOfferState(status);
      }
    }
    if (status === 'PENDING_SELLER_AGENT_RESPONSE') {
      const { value: accept } = await Swal.fire({
        title: 'Confirm Send to Seller',
        text: 'If accepted the terms of the OFFER will be populated in the state-wide accepted Purchase Agreement and sent for your signature.',
        input: 'checkbox',
        inputValue: 0,
        inputPlaceholder: 'I have the authority to act on behalf of all listed buyer(s) in the offer presented.',
        confirmButtonText: 'Continue',
        inputValidator: (result) => {
          return !result && 'You need to agree';
        },
      });

      if (accept) {
        updateOfferState(status);
      }
    }
    if (status === 'BUYER_REJECTED') {
      const { value: comment } = await Swal.fire({
        title: 'Reject Agent Draft Proposal',
        input: 'textarea',
        text: 'Once you click the button below your agent will be notified to make any corrections or updates you requested. When your agent makes the correction you will be re-sent an email to review and approve the offer.',
        showCancelButton: true,
        confirmButtonText: 'Send back to Agent for Changes',
        inputValidator: (result) => {
          return !result && 'This field is required';
        },
      });

      if (comment) {
        updateOfferState(status, comment);
      }
    }
    if (status === 'DRAFT') {
      updateOfferState('DRAFT');
      history.push(`/offers/${offer.id}`);
    }
  };

  const updateOfferState = (status, comment) => {
    reduxDispatch(CreateOfferAsyncActions.UpdateState.Actions.REQUEST(offer.id, status, comment));
  };

  const handleCancel = () => {
    reduxDispatch(CreateOfferAsyncActions.DeleteOffer.Actions.REQUEST(offer.id));
  };

  const handleBack = (backToReview = false) => {
    if (stepNumber === END_STEP) {
      setStepNumber(END_STEP - 1);
      return;
    }
    if (checkRequirements(stepNumber)) {
      return;
    }
    if (backToReview) {
      setStepNumber(END_STEP);
    } else {
      setStepNumber(stepNumber - 1);
    }
    saveOffer(offer);
  };

  const handleContinue = () => {
    if (stepNumber >= offerSteps.length || checkRequirements(stepNumber)) {
      return;
    }
    if (stepNumber === offerSteps.length - 1) {
      setIsReview(true);
    }
    setStepNumber(stepNumber + 1);
    saveOffer(offer);
  };

  const checkComments = (comments, maxLength) => {
    const commentsLength = maxLength ?? 500;
    return comments && comments.trim().length > commentsLength ? true : false;
  };

  const checkError = (err, selectors) => {
    return Object.keys(err).some((key) => {
      const el = err[key];
      if (typeof el === 'object') {
        return checkError(el);
      }
      if (el) {
        Swal.fire({
          icon: 'warning',
          title: 'Some Answers Required',
          text: 'Please fill out all fields marked with an asterisk to continue.',
          confirmButtonText: 'Continue',
        }).then(() => {
          setTimeout(() => {
            document.querySelector(`${selectors?.[key] || '#' + key}`)?.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          }, 300);
        });
      }
      return !!el;
    });
  };

  const checkRequirements = (step) => {
    switch (step) {
      case 1: {
        const { primaryBuyer, secondaryBuyers } = offer;
        const hasSecondaryBuyers = primaryBuyer.ownerType === 'INDIVIDUAL' || primaryBuyer.ownerType === 'TRUST';

        const primaryBuyerError = {
          primaryBuyer: !primaryBuyer || !primaryBuyer.ownerType,
          user: {
            firstName: !primaryBuyer?.user?.firstName && 'First Name is required',
            lastName: !primaryBuyer?.user?.lastName && 'Last Name is required',
            email: !primaryBuyer?.user?.email
              ? 'Email is required'
              : primaryBuyer?.user?.email === listing?.listingAgent?.email
              ? "You cannot use the seller agent's email"
              : hasSecondaryBuyers && secondaryBuyers.some((buyer) => buyer.user.email === primaryBuyer.user.email)
              ? 'Primary buyer email cannot be the same as secondary buyer email'
              : '',
            phone: !primaryBuyer?.user?.phone && 'Mobile is required',
            orgName: primaryBuyer.ownerType !== 'INDIVIDUAL' && !primaryBuyer?.orgName && 'This field is required',
          },
          certifyBuyer: primaryBuyer?.certifyBuyer !== true,
        };
        const secondaryBuyersError = {};

        if (hasSecondaryBuyers) {
          secondaryBuyers?.forEach((buyer, index) => {
            secondaryBuyersError[index] = {
              firstName: !buyer?.user?.firstName && 'First Name is required',
              lastName: !buyer?.user?.lastName && 'Last Name is required',
              email: !buyer?.user?.email
                ? 'Email is required'
                : buyer?.user?.email === listing?.listingAgent?.email
                ? "You cannot use the seller agent's email"
                : buyer?.user?.email === primaryBuyer.user.email
                ? 'Secondary buyer email cannot be the same as primary buyer email'
                : secondaryBuyers.some(
                    (secBuyer, secIndex) => secIndex !== index && secBuyer.user.email === buyer.user.email
                  )
                ? 'Secondary buyer email cannot be the same as another secondary buyer email'
                : '',
              phone: !buyer?.user?.phone && 'Mobile is required',
            };
          });
        }
        setErrors((prevErrors) => ({
          ...prevErrors,
          primaryBuyer: primaryBuyerError,
          secondaryBuyers: secondaryBuyersError,
        }));

        return checkError({ primaryBuyer: primaryBuyerError, secondaryBuyers: secondaryBuyersError });
      }
      case 2: {
        const { terms } = offer;
        const termsError = {
          ownershipPurpose: !terms?.ownershipPurpose,
          offerAmount: !terms?.offerAmount,
          useDaysToClose: isNil(terms?.useDaysToClose),
          daysToClose: terms?.useDaysToClose === true && !terms?.daysToClose,
          exactClosingDate:
            terms?.useDaysToClose === false &&
            !moment(moment(terms?.exactClosingDate).format('YYYY-MM-DD'), 'YYYY-MM-DD', true).isValid(),
          loan: isNil(terms?.loan),
          loanType: terms?.loan && !terms?.loanType,
          preapproved: !!listing?.proofOfFundsRequired && !!terms?.loan && isNil(terms?.preapproved),
          downPaymentPercentage: terms?.loan && isNil(terms?.downPaymentPercentage),
          downPaymentDollar: terms?.loan && isNil(terms?.downPaymentDollar),
          loanAmountPercentage: terms?.loan && isNil(terms?.loanAmountPercentage),
          loanAmountDollar: terms?.loan && isNil(terms?.loanAmountDollar),
          earnestDepositPercentage: isNil(terms?.earnestDepositPercentage),
          earnestDepositDollar: isNil(terms?.earnestDepositDollar),
          termsComments: checkComments(terms?.termsComments),
        };
        setErrors((prevErrors) => ({
          ...prevErrors,
          terms: termsError,
        }));
        return checkError(termsError);
      }
      case 3: {
        const { contingencies } = offer;
        const isEscrow =
          contingencies?.contingentOnSale && contingencies?.propertyListedForSale && contingencies?.inEscrow;
        const contingenciesError = {
          contingentOnSale: isNil(contingencies?.contingentOnSale),
          propertyListedForSale: contingencies?.contingentOnSale && isNil(contingencies?.propertyListedForSale),
          listingDate:
            contingencies?.contingentOnSale && contingencies?.propertyListedForSale && !contingencies?.listingDate,
          inEscrow:
            contingencies?.contingentOnSale && contingencies?.propertyListedForSale && isNil(contingencies?.inEscrow),
          escrowClosedDate: isEscrow && !contingencies?.escrowClosedDate,
          escrowNumber: isEscrow && !contingencies?.escrowNumber,
          escrowCompany: isEscrow && !contingencies?.escrowCompany,
          escrowOfficerName: isEscrow && !contingencies?.escrowOfficerName,
          contingentOnLoan: isNil(contingencies?.contingentOnLoan),
          daysLoan: contingencies?.contingentOnLoan && !contingencies?.daysLoan,
          contingentOnAppraisal: listing?.address?.state === 'CA' && isNil(contingencies?.contingentOnAppraisal),
          daysAppraisal:
            listing?.address?.state !== 'FL' && contingencies?.contingentOnAppraisal && !contingencies?.daysAppraisal,
          contingentOnInspection: isNil(contingencies?.contingentOnInspection),
          daysInspection: contingencies?.contingentOnInspection && !contingencies?.daysInspection,
          daysRequiredOfBuyerReviewDisclosures:
            isCalifornia &&
            (isNil(contingencies?.daysRequiredOfBuyerReviewDisclosures) ||
              contingencies?.daysRequiredOfBuyerReviewDisclosures === ''),
          contingenciesComments: checkComments(contingencies?.contingenciesComments),
        };
        setErrors((prevErrors) => ({
          ...prevErrors,
          contingencies: contingenciesError,
        }));
        return checkError(contingenciesError);
      }

      case 4: {
        const { creditItems } = offer;
        const creditItemsError = {
          sellerPaysBuyerBroker: isCalifornia && isNil(creditItems?.requestSeller),
          sellerPaysBuyerBrokerDollar:
            isCalifornia && creditItems?.requestSeller && !creditItems?.sellerPaysBuyerBrokerDollar,
          sellerPaysBuyerBrokerPercentage:
            isCalifornia && creditItems?.requestSeller && !creditItems?.sellerPaysBuyerBrokerPercentage,

          requestSeller: isCalifornia && isNil(creditItems?.requestSeller),
          closingCostCreditDollar: isCalifornia && creditItems?.requestSeller && !creditItems?.closingCostCreditDollar,
          closingCostCreditPercentage:
            isCalifornia && creditItems?.requestSeller && !creditItems?.closingCostCreditPercentage,
          creditItemsComments: checkComments(creditItems?.creditItemsComments),
        };
        setErrors((prevErrors) => ({
          ...prevErrors,
          creditItems: creditItemsError,
        }));
        return checkError(creditItemsError);
      }
      case 5: {
        const services = offer?.services ?? {};
        const servicesError = {
          warrantyIncluded: isNil(services?.warrantyIncluded),
          warrantyCompany: services?.warrantyIncluded && !services?.warrantyCompany,
          warrantyMaxCost: services?.warrantyIncluded && !services?.warrantyMaxCost,
          warrantyPayer: services?.warrantyIncluded && !services?.warrantyPayer,
          titleCompany: {
            titleCompanyName:
              isCalifornia && services.confirmationServices === 'BUYER' && !services?.titleCompany?.name,
          },
          titlePayer: isCalifornia && isNil(services?.titlePayer),
          escrowCompany: {
            escrowCompanyName:
              isCalifornia && services.confirmationServices === 'BUYER' && !services?.escrowCompany?.name,
          },
          escrowPayer: isCalifornia && isNil(services?.escrowPayer),
          hazardCompany: {
            hazardCompanyName:
              isCalifornia && services.confirmationServices === 'BUYER' && !services?.hazardCompany?.name,
          },
          hazardPayer: isCalifornia && isNil(services?.hazardPayer),
          servicesComments: checkComments(services?.servicesComments),
        };
        setErrors((prevErrors) => ({
          ...prevErrors,
          services: servicesError,
        }));
        return checkError(servicesError);
      }
      case 6: {
        const { costAllocations } = offer;
        const costAllocationsError = {
          creditItemsComments: checkComments(costAllocations?.costAllocationsComments),
        };
        setErrors((prevErrors) => ({
          ...prevErrors,
          costAllocations: costAllocationsError,
        }));
        return checkError(costAllocationsError);
      }

      case 7: {
        const { otherTerms, buyerAddenda } = offer;
        const addendaItems = isFlorida ? buyerAddendaItemsFL : buyerAddendaItemsCA;
        const otherTermsError = {
          otherTerms: checkComments(otherTerms, isFlorida ? 1800 : 260),
          buyerAddenda: addendaItems.reduce((acc, addendum) => {
            if (buyerAddenda?.[addendum.name] && !buyerAddenda?.[`${addendum.name}File`]) {
              acc[addendum.name] = true;
            }
            return acc;
          }, {}),
        };
        setErrors((prevErrors) => ({
          ...prevErrors,
          otherTerms: otherTermsError,
        }));
        return checkError(otherTermsError);
      }

      case 8: {
        const { proofOfFunds, loanApproval } = offer;
        const reviewError = {
          proofOfFunds: !!listing?.proofOfFundsRequired && isNil(proofOfFunds),
          loanApproval: !!listing?.proofOfFundsRequired && !!offer?.terms?.loan && isNil(loanApproval),
        };
        setErrors((prevErrors) => ({
          ...prevErrors,
          review: reviewError,
        }));
        return checkError(reviewError);
      }
      default:
        return false;
    }
  };

  const handleSave = () => {
    saveOffer(offer);
  };

  const handleCounter = () => {
    onCounter(offer);
  };

  const handleOfferChange = (action) => async (data) => {
    setShowSavedNow(false);
    if (action === actionType.OFFER_UPDATE_CREDIT_ITEMS) {
      if (!offer.creditItems?.requestSeller && data.requestSeller) {
        Swal.fire({
          title: 'Offer Credit and Items Notice',
          text: 'If you choose to select a credit to help cover buyer costs, you are in effect lowering the net to seller and potentially weakening your offer. It is recommended that buyers and their agent discuss this prior to making any such request. If appropriate, you may increase your offer to offset any requested credit.',
        });
      }
      if (!offer.creditItems?.sellerPaysBuyerBroker && data.sellerPaysBuyerBroker) {
        Swal.fire({
          title: 'Offer Credit and Items Notice',
          text: 'If you choose to request the seller to pay the buyer agent commission for the buyer, you are in effect lowering the net to seller and potentially weakening your offer. It is recommended that buyers and their agent discuss this prior to making any such request. If appropriate, you may increase your offer to offset any requested credit.',
        });
      }
    }
    setDirty(true);
    await dispatch({
      type: action,
      payload: data,
      listingState: listing?.address?.state,
    });
    if (action === actionType.OFFER_UPDATE_REVIEW) {
      saveOffer(getOffer());
    }
  };

  const handleViewRpa = () => {
    URLUtils.openDocument(`rpa/${offer.listingId}/rpa-${offer.id}.pdf`);
  };

  const isError = !!createError || !!updateError || !!updateStateError;

  return (
    <React.Fragment>
      <Helmet>
        <title>{`${existingOffer ? 'Update' : 'Create'} an offer`}</title>
        <meta name='description' content={`${existingOffer ? 'Update' : 'Create'} an offer`} />
      </Helmet>
      {!isAgent && !isOfferBuyer && (
        <OfferAgentPending>
          <h3>Looks like you aren't certified to create Offers</h3>
          <h5>Ensure you've completed your agent profile!</h5>
          <ContinueButton className='mt-3' onClick={() => history.push(routes.AGENT_PROFILE_PATH)}>
            COMPLETE AGENT PROFILE
          </ContinueButton>
        </OfferAgentPending>
      )}
      {(isAgent || isOfferBuyer) && (
        <React.Fragment>
          {listing && (
            <ListingInfo
              address={listing.address}
              price={listing.price}
              offerCount={offerCount}
              highestOffer={highestOffer}
              globalCounterState={listing.globalCounter?.globalCounterDetails?.state}
            />
          )}
          {isError && <h4 className='mx-auto mt-4'>Something went wrong with saving your offer</h4>}
          {!isError && (
            <React.Fragment>
              <div className='create-listing-step'>
                <CreateTopNavBar
                  isEnd={stepNumber === offerSteps.length}
                  isOfferBuyer={isOfferBuyer}
                  isBuyerAgent={isBuyerAgent}
                  showRescind={
                    stepNumber === END_STEP &&
                    isAgent &&
                    offer.state !== 'DRAFT' &&
                    offer.state !== 'LIVE_EDIT' &&
                    offer.state !== 'ACCEPTED' &&
                    offer.state !== 'BUYER_REJECTED' &&
                    !isCounter
                  }
                  status={offer.state}
                  comment={offer.state === 'BUYER_REJECTED' && offer.comment ? offer.comment : null}
                  onUpdate={showSubmitModal}
                />
                {!isCounter && (
                  <CreateHeader
                    stepNumber={stepNumber}
                    stepName={offerSteps[stepNumber - 1].name}
                    totalSteps={offerSteps.length}
                  />
                )}
                <Divider />
                <div className={stepNumber !== 8 ? 'py-4 px-md-4 px-2' : 'pt-1'}>
                  {stepNumber === 1 && (
                    <OfferContact
                      primaryBuyer={offer.primaryBuyer}
                      secondaryBuyers={offer.secondaryBuyers}
                      onStateChanged={handleOfferChange(actionType.OFFER_UPDATE_CONTACT_INFO)}
                      onSecondaryBuyerChanged={handleOfferChange(actionType.OFFER_UPDATE_SECONDARY_BUYERS)}
                      errors={errors.primaryBuyer}
                      secondaryErrors={errors.secondaryBuyers}
                    />
                  )}
                  {stepNumber === 2 && (
                    <OfferTerms
                      listingState={listing?.address?.state}
                      listingId={listing?.id}
                      terms={offer.terms}
                      ownershipPurpose={ownershipPurpose}
                      loanTypes={loanTypes}
                      proofOfFundsRequired={listing?.proofOfFundsRequired}
                      sellerFinancingAvailable={listing?.sellerFinancingAvailable}
                      onStateChanged={handleOfferChange(actionType.OFFER_UPDATE_TERM)}
                      isCounter={isCounter}
                      errors={errors.terms}
                    />
                  )}
                  {stepNumber === 3 && (
                    <OfferContingencies
                      listingState={listing?.address?.state}
                      contingencies={offer.contingencies ?? {}}
                      stateData={stateData}
                      onChange={handleOfferChange(actionType.OFFER_UPDATE_CONTINGENCIES)}
                      onStateDataChange={handleOfferChange(actionType.OFFER_UPDATE_STATE_DATA)}
                      isCounter={isCounter}
                      errors={errors.contingencies}
                    />
                  )}
                  {stepNumber === 4 && (
                    <OfferCreditItems
                      listingState={listing?.address?.state}
                      creditItems={offer.creditItems}
                      listingPrice={offer.terms?.offerAmount}
                      offerRpaBreachContract={offer?.rpaBreachContract}
                      offerRpaDisputeResolution={offer?.rpaDisputeResolution}
                      listingRpaBreachContract={listing?.rpaBreachContract}
                      listingRpaDisputeResolution={listing?.rpaDisputeResolution}
                      onStateChanged={handleOfferChange(actionType.OFFER_UPDATE_CREDIT_ITEMS)}
                      onRpaUpdate={handleOfferChange(actionType.OFFER_UPDATE_REVIEW)}
                      listing={listing}
                      isCounter={isCounter}
                      errors={errors.creditItems}
                    />
                  )}
                  {stepNumber === 5 && (
                    <>
                      {isCalifornia && (
                        <OfferServicesCA
                          services={offer.services ?? {}}
                          onStateChanged={handleOfferChange(actionType.OFFER_UPDATE_SERVICES)}
                          isCounter={isCounter}
                          errors={errors.services}
                        />
                      )}
                      {isFlorida && (
                        <OfferServicesFL
                          services={offer.services ?? {}}
                          stateData={stateData}
                          isCounter={isCounter}
                          onChange={handleOfferChange(actionType.OFFER_UPDATE_SERVICES)}
                          onStateDataChange={handleOfferChange(actionType.OFFER_UPDATE_STATE_DATA)}
                          errors={errors.services}
                        />
                      )}
                    </>
                  )}
                  {stepNumber === 6 && (
                    <>
                      {isCalifornia && (
                        <OfferCostAllocationsCA
                          costAllocations={offer.costAllocations ?? {}}
                          onStateChanged={handleOfferChange(actionType.OFFER_UPDATE_COST_ALLOCATIONS)}
                          isCounter={isCounter}
                          errors={errors.costAllocations}
                        />
                      )}
                      {isFlorida && (
                        <OfferCostAllocationsFL
                          costAllocations={offer.costAllocations ?? {}}
                          stateData={stateData}
                          onChange={handleOfferChange(actionType.OFFER_UPDATE_COST_ALLOCATIONS)}
                          onStateDataChange={handleOfferChange(actionType.OFFER_UPDATE_STATE_DATA)}
                          isCounter={isCounter}
                          errors={errors.costAllocations}
                        />
                      )}
                    </>
                  )}
                  {stepNumber === 7 && (
                    <OtherTermsAddenda
                      listingState={listing?.address?.state}
                      otherTerms={offer.otherTerms}
                      propertyAddenda={listing?.propertyAddenda}
                      buyerAddenda={offer.buyerAddenda}
                      remainInPropertyAnswer={listing?.remainInPropertyAnswer}
                      remainInPropertyFile={listing?.remainInPropertyFile}
                      customAddendum={listing?.customAddendum}
                      customAddendumFile={listing?.customAddendumFile}
                      onStateChanged={handleOfferChange(actionType.OFFER_UPDATE_OTHER_TERMS_ADDENDA)}
                      errors={errors?.otherTerms}
                    />
                  )}
                  {stepNumber === 8 && (
                    <OfferReview
                      offer={offer}
                      listing={listing}
                      onStateChanged={handleOfferChange(actionType.OFFER_UPDATE_REVIEW)}
                      onHandleJumpToStep={(step) => setStepNumber(step)}
                      onSave={handleSave}
                      steps={offerSteps}
                      isBuyerAgent={isBuyerAgent}
                      isBuyer={isOfferBuyer}
                      collapseAll={isCounter}
                      editLabel={!isCounter ? 'Edit' : 'Counter'}
                      isCounter={isCounter}
                      errors={errors?.review}
                      readOnly={!isBuyerAgent && isOfferBuyer}
                    />
                  )}
                </div>

                <Divider />
                <CreateBottomNavBar
                  showBack={
                    !isCounter &&
                    stepNumber > 1 &&
                    offer.state !== 'PENDING_BUYER_APPROVAL' &&
                    offer.state !== 'LIVE_EDIT_BUYER'
                  }
                  isEnd={stepNumber === offerSteps.length}
                  isReview={isReview}
                  onHandleCancel={handleCancel}
                  onBack={handleBack}
                  onContinue={handleContinue}
                  onSave={handleSave}
                  onSaveDraft={handleSave}
                  onUpdateStatus={showSubmitModal}
                  isOfferBuyer={isOfferBuyer}
                  isBuyerAgent={isBuyerAgent}
                  status={offer.state}
                  isListing={false}
                  canSubmit={true}
                  onHandleViewRpa={handleViewRpa}
                  onDelete={handleCancel}
                  showDelete={stepNumber === END_STEP && !isCounter}
                  showContinue={!isCounter && stepNumber !== offerSteps.length}
                  isCounter={isCounter}
                  onCounter={isCounter ? handleCounter : () => {}}
                  dirty={dirty}
                  offerType={offer.offerType}
                />
                {showSavedNow && (
                  <div className='ms-2 d-flex align-items-center'>
                    <FaRegSave size={20} className='me-2' />
                    Saved Now
                  </div>
                )}
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default CreateOfferPage;
