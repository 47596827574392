import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { CardDeck, OfferCard } from 'components';
import ConsumerProfilePage from 'containers/AgentProfilePage/consumerProfile';
import { BuyersAsyncActions } from 'store/actions/buyers';
import { getBuyer, getBuyerProfiles } from 'store/selectors/buyers';
import { BuyerTitle, CloseButton, TabItem, ActiveBadge } from './activeOfferModal.styled';
import BuyerProfiles from 'containers/BuyerProfile/buyerProfiles';

function ActiveOfferModal({ buyer, onClose }) {
  const [currentTab, setCurrentTab] = useState('offers');
  const dispatch = useDispatch();
  const activeBuyer = useSelector(getBuyer);
  const profiles = useSelector(getBuyerProfiles);

  useEffect(() => {
    dispatch(BuyersAsyncActions.FetchBuyer.Actions.REQUEST(buyer.user.id));
  }, [dispatch, buyer]);

  useEffect(() => {
    dispatch(BuyersAsyncActions.FetchBuyerProfiles.Actions.REQUEST(buyer.user.id));

    return () => {
      dispatch(BuyersAsyncActions.FetchBuyerProfiles.Actions.RESET());
    };
  }, [dispatch, buyer]);

  return (
    <Modal.Dialog className='shadow-sm' size='xl'>
      <Modal.Header className='align-items-center px-2 py-1' onHide={onClose} style={{ color: 'black' }}>
        <div className='d-flex'>
          <div>
            <BuyerTitle>Primary Buyer</BuyerTitle>
            <h4>
              {buyer.user.firstName} {buyer.user.lastName}
            </h4>
          </div>
          {buyer.secondary && (
            <div className='ms-5'>
              <BuyerTitle>Secondary Buyer</BuyerTitle>
              <h4>
                {buyer.user.firstName} {buyer.user.lastName}
              </h4>
            </div>
          )}
        </div>
        <CloseButton onClick={onClose}>&times;</CloseButton>
      </Modal.Header>
      <Modal.Header className='p-0 pt-2 justify-content-start'>
        <TabItem active={currentTab === 'offers'} onClick={() => setCurrentTab('offers')}>
          Active Offers
          <ActiveBadge pill>{activeBuyer ? activeBuyer.length : 0}</ActiveBadge>
        </TabItem>
        <TabItem active={currentTab === 'details'} onClick={() => setCurrentTab('details')}>
          Buyer Details
        </TabItem>
        <TabItem active={currentTab === 'profiles'} onClick={() => setCurrentTab('profiles')}>
          Profiles
        </TabItem>
      </Modal.Header>
      <Modal.Body>
        {currentTab === 'offers' && activeBuyer && (
          <CardDeck center>
            {activeBuyer.map((offer) => (
              <OfferCard key={offer.listing.id} offer={offer} user={buyer.user} fromBuyer />
            ))}
          </CardDeck>
        )}
        {currentTab === 'details' && <ConsumerProfilePage user={buyer.user} onUpdateUser={() => {}} readOnly />}
        {currentTab === 'profiles' && <BuyerProfiles profiles={profiles?.profiles} />}
      </Modal.Body>
    </Modal.Dialog>
  );
}

ActiveOfferModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ActiveOfferModal;
