import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import {
  RadioButtons,
  CheckBox,
  User,
  Address,
  Corporation,
  Partnership,
  Trust,
  Divider,
  Typography,
} from 'components';
import { useResponsive } from 'hooks';
import { isSameAddress } from 'utils';
import { getOwnerTypes } from 'store/selectors/app';

function CreatePropertyOwner({
  onStateChanged,
  onPropertyAddress,
  ownerAddress,
  ownerType,
  owner,
  secondaryOwners,
  defaultAddress,
  orgName,
  ownerReports,
  onFileChanged,
  onFileDeleted,
  certifyOwnersCorrect,
  errors,
  secondaryOwnersErrors,
}) {
  const [useDefault, setUseDefault] = useState(isSameAddress(defaultAddress, ownerAddress));
  const [additionalOwners, setAdditionalOwners] = useState(secondaryOwners);
  const [additionalOwnersSameAddress, setAdditionalOwnersSameAddress] = useState(
    secondaryOwners.map((owner) => isSameAddress(owner.address, defaultAddress))
  );

  const items = useSelector(getOwnerTypes);

  const isMobile = useResponsive();

  useEffect(() => {
    onStateChanged('secondaryOwners', additionalOwners);
    // eslint-disable-next-line
  }, [additionalOwners]);

  const onAddressChange = (address) => {
    onStateChanged('ownerAddress', address);
  };

  const handleUserChanged = (user) => {
    onStateChanged('owner', user);
  };

  const handleUsePropertyAddress = () => {
    onPropertyAddress(!useDefault);
    setUseDefault(!useDefault);
  };

  const handleOrgChanged = (state) => {
    onStateChanged('orgName', state.value);
  };

  const onOwnerTypeChanged = (event) => {
    onStateChanged('ownerType', event.target.value);
  };

  const onOwnersCorrectChanged = (event) => {
    onStateChanged('certifyOwnersCorrect', event.target.checked);
  };

  const handleFileDeleted = (fileType) => {
    onFileDeleted(fileType);
  };

  const handleAddOwner = () => {
    const owners = [...additionalOwners];
    owners.push({
      address: { ...ownerAddress },
      user: {},
    });
    setAdditionalOwners(owners);
    setAdditionalOwnersSameAddress([...additionalOwnersSameAddress, useDefault]);
  };

  const handleRemoveOwner = (index) => {
    const owners = [...additionalOwners];
    const newSameAddress = [...additionalOwnersSameAddress];
    owners.splice(index, 1);
    newSameAddress.splice(index, 1);
    setAdditionalOwners(owners);
    setAdditionalOwnersSameAddress(newSameAddress);
  };

  const handleAdditionalOwnerChanged = (user, index) => {
    const owners = [...additionalOwners];
    owners[index].user = user;
    setAdditionalOwners(owners);
  };

  const handleAdditionalOwnerAddressChanged = (address, index) => {
    const owners = [...additionalOwners];
    owners[index].address = address;
    setAdditionalOwners(owners);
  };

  const handleAdditionalOwnersSameAddressChange = (index) => {
    if (!additionalOwnersSameAddress[index]) {
      const owners = [...additionalOwners];
      owners[index].address = defaultAddress;
      setAdditionalOwners(owners);
    }
    const newSameAddress = [...additionalOwnersSameAddress];
    newSameAddress[index] = !additionalOwnersSameAddress[index];
    setAdditionalOwnersSameAddress(newSameAddress);
  };

  return (
    <>
      <p id='primaryOwner'>The Owner is a:</p>
      <RadioButtons
        className='mb-2'
        items={items}
        groupName='owner-type'
        selectedOption={ownerType || 'INDIVIDUAL'}
        onStateChanged={onOwnerTypeChanged}
        vertical={isMobile}
        error={errors?.primaryOwner}
      />
      {ownerType === 'CORPORATION' && (
        <Corporation
          name={orgName}
          affidavit={ownerReports['signerAffidavit']}
          articles={ownerReports['articlesOfIncorporation']}
          user={owner}
          onUserChanged={handleUserChanged}
          onCorpChanged={handleOrgChanged}
          onFileChanged={onFileChanged}
          onDeleteFile={handleFileDeleted}
          errors={errors?.user}
        />
      )}
      {ownerType === 'PARTNERSHIP' && (
        <Partnership
          name={orgName}
          affidavit={ownerReports['signerAffidavit']}
          partnership={ownerReports['partnershipAgreement']}
          user={owner}
          onUserChanged={handleUserChanged}
          onCorpChanged={handleOrgChanged}
          onFileChanged={onFileChanged}
          onDeleteFile={handleFileDeleted}
          errors={errors?.user}
        />
      )}
      {ownerType === 'TRUST' && (
        <Trust
          name={orgName}
          affidavit={ownerReports['signerAffidavit']}
          user={owner}
          onUserChanged={handleUserChanged}
          onCorpChanged={handleOrgChanged}
          onFileChanged={onFileChanged}
          onDeleteFile={handleFileDeleted}
          errors={errors?.user}
        />
      )}
      {(ownerType === 'INDIVIDUAL' || !ownerType) && (
        <User user={owner} title={'Owner'} editable={true} onChanged={handleUserChanged} errors={errors?.user} />
      )}
      <CheckBox className='my-2' name='useDefault' checked={useDefault} onChange={handleUsePropertyAddress}>
        Same as Listing Address
      </CheckBox>
      <Collapse in={!useDefault}>
        <div id='owner-address' className='my-2'>
          <Address onStateChanged={onAddressChange} address={ownerAddress || {}} errors={errors?.address} />
        </div>
      </Collapse>
      <Button className='my-2 d-block' onClick={handleAddOwner}>
        + ADDITIONAL OWNER
      </Button>

      {additionalOwners.map((owner, index) => (
        <div className='mt-3'>
          <User
            user={owner.user ? owner.user : {}}
            title={'Additional Owner'}
            editable={true}
            onChanged={(user) => handleAdditionalOwnerChanged(user, index)}
            removable
            onRemove={() => handleRemoveOwner(index)}
            errors={secondaryOwnersErrors?.[index]?.user}
          />
          <CheckBox
            className='my-2'
            name={`additionalOwnersSameAddress_${index}`}
            checked={additionalOwnersSameAddress[index]}
            onChange={() => handleAdditionalOwnersSameAddressChange(index)}
          >
            Same as Listing Address
          </CheckBox>
          <Collapse in={!additionalOwnersSameAddress[index]}>
            <div id='owner-address' className='my-2'>
              <Address
                onStateChanged={(address) => handleAdditionalOwnerAddressChanged(address, index)}
                address={owner.address ?? {}}
                errors={secondaryOwnersErrors?.[index]?.address}
              />
            </div>
          </Collapse>
        </div>
      ))}
      <Divider className='mx-n2 mx-md-n4 my-3' />
      <Typography bold>
        Sellers are <u>NOT</u> notified of this listing until you "publish" the listing in the last step of this
        process.
      </Typography>
      <Divider className='mx-n2 mx-md-n4 my-3' />
      <CheckBox
        name='certifyOwnersCorrect'
        checked={certifyOwnersCorrect}
        onChange={onOwnersCorrectChanged}
        error={errors?.certifyOwnersCorrect}
      >
        I certify the owner(s) name and contact information are true and correct.{' '}
        <Typography color='danger'>Required*</Typography>
      </CheckBox>
    </>
  );
}

export default CreatePropertyOwner;
