import React from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Icon, ButtonWrapper } from 'components';
import { useAuthUser } from 'hooks';
import { formatCurrency, formatPhoneNumber, formatPrettyPercentage, isDateValid, isParamsNil } from 'utils';
import { Container, SectionTitle, SectionItem, SectionEdit } from './offerCommon.styled';
import { isNil } from 'lodash';

function OfferTerms({
  isSellerPreference = false,
  placeholder = false,
  sellerPreferenceOffer,
  offer,
  globalCounter,
  isGlobalCounter,
  isEditing = false,
  onEditOfferAmount,
}) {
  const { isAgent } = useAuthUser();

  const getDaysToClose = (isDays, days, date) => {
    if (isSellerPreference) return '';

    if (isDays) {
      return !isNil(days) || isNaN(days) ? days + ' Days' : 'Not Specified';
    } else {
      return isDateValid(date, 'YYYY-MM-DD') ? moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY') : 'Not Specified';
    }
  };

  return (
    <Container preference={isSellerPreference} $placeholder={placeholder}>
      {!placeholder && (
        <>
          <SectionTitle>{isSellerPreference && 'Initiating Agent'}</SectionTitle>
          <SectionItem>
            {!isSellerPreference && (
              <>
                <div>
                  {offer.createdByUser?.firstName} {offer.createdByUser?.lastName}
                </div>
                {isAgent && (
                  <>
                    {offer.createdByUser?.phone && <span>{formatPhoneNumber(offer.createdByUser.phone)}</span>}
                    <CopyToClipboard
                      text={offer.createdByUser?.email}
                      onCopy={() => toast.success('Copied to clipboard', { icon: '✉️' })}
                    >
                      <ButtonWrapper className='ms-4'>
                        <Icon name='envelope' />
                      </ButtonWrapper>
                    </CopyToClipboard>
                  </>
                )}
              </>
            )}
          </SectionItem>
          <SectionTitle>
            <SectionEdit>
              {isSellerPreference && 'Offer'}
              {!isSellerPreference && onEditOfferAmount && <Icon name='pencil-square' onClick={onEditOfferAmount} />}
            </SectionEdit>
          </SectionTitle>
          <SectionItem highlight={globalCounter && offer.offerAmount !== globalCounter.offerAmount}>
            {isSellerPreference &&
              !isParamsNil(sellerPreferenceOffer.listPrice) &&
              formatCurrency(sellerPreferenceOffer.listPrice ?? 0)}
            {!isSellerPreference && !isGlobalCounter && !isEditing && formatCurrency(offer.offerAmount)}
            {isEditing && 'Details being edited'}
          </SectionItem>
          <SectionTitle>{isSellerPreference && 'Net Amount'}</SectionTitle>
          <SectionItem
            highlight={
              globalCounter &&
              offer.offerAmount -
                offer.creditItems?.closingCostCreditDollar -
                offer.creditItems?.sellerPaysBuyerBrokerDollar !==
                globalCounter.offerAmount -
                  globalCounter.creditItems?.closingCostCreditDollar -
                  globalCounter.creditItems?.sellerPaysBuyerBrokerDollar
            }
          >
            {!isSellerPreference &&
              !isGlobalCounter &&
              !isEditing &&
              formatCurrency(
                offer.offerAmount -
                  offer.creditItems?.closingCostCreditDollar -
                  offer.creditItems?.sellerPaysBuyerBrokerDollar
              )}
            {isEditing && 'Details being edited'}
          </SectionItem>
          <SectionTitle>{isSellerPreference && 'Received'}</SectionTitle>
          <SectionItem>
            {!isSellerPreference &&
              (offer.createTime ? moment(offer.createTime).format('MM/DD/YYYY') : 'Not Specified')}
          </SectionItem>
          <SectionTitle
            match={
              !isSellerPreference &&
              !isParamsNil(sellerPreferenceOffer.closeTimeDays) &&
              sellerPreferenceOffer.closeTimeDays === offer.terms.daysToClose
            }
          >
            {isSellerPreference && 'Days to Close'}
          </SectionTitle>
          <SectionItem highlight={globalCounter && offer.terms.daysToClose !== globalCounter.terms.daysToClose}>
            {isSellerPreference &&
              getDaysToClose(
                sellerPreferenceOffer.closeTimeUseDays,
                sellerPreferenceOffer.closeTimeDays,
                sellerPreferenceOffer.closeTimeStart
              )}

            {!isSellerPreference &&
              !isEditing &&
              offer.terms &&
              getDaysToClose(offer.terms.useDaysToClose, offer.terms.daysToClose, offer.terms.exactClosingDate)}
            {isEditing && 'Details being edited'}
          </SectionItem>
          <SectionTitle
            match={
              !isSellerPreference &&
              !isParamsNil(sellerPreferenceOffer.earnestMoney) &&
              sellerPreferenceOffer.earnestMoney === offer.terms.earnestDepositPercentage
            }
          >
            {isSellerPreference && 'Earnest Money Deposit'}
          </SectionTitle>
          <SectionItem
            highlight={
              globalCounter &&
              (offer.terms.earnestDepositPercentage !== globalCounter.terms.earnestDepositPercentage ||
                offer.terms.earnestDepositDollar !== globalCounter.terms.earnestDepositDollar)
            }
          >
            {isSellerPreference &&
              !isParamsNil(sellerPreferenceOffer.earnestMoney) &&
              sellerPreferenceOffer.earnestMoney + '%'}
            {!isSellerPreference &&
              !isEditing &&
              formatPrettyPercentage(offer.terms.earnestDepositPercentage, 2) +
                ' | ' +
                formatCurrency(offer.terms.earnestDepositDollar)}
            {isEditing && 'Details being edited'}
          </SectionItem>
          <SectionTitle>{isSellerPreference && 'Additional Deposit Day(s) to deliver to escrow'}</SectionTitle>
          <SectionItem
            highlight={globalCounter && offer.terms.earnestDepositDays !== globalCounter.terms.earnestDepositDays}
          >
            {!isSellerPreference &&
              !isEditing &&
              !isNil(offer.terms.earnestDepositDays) &&
              offer.terms.earnestDepositDays + ' Days'}
            {isEditing && 'Details being edited'}
          </SectionItem>
          <SectionTitle>{isSellerPreference && 'Additional Deposit'}</SectionTitle>
          <SectionItem
            highlight={
              globalCounter &&
              (offer.terms.increasedDepositPercentage !== globalCounter.terms.increasedDepositPercentage ||
                offer.terms.increasedDepositDollar !== globalCounter.terms.increasedDepositDollar)
            }
          >
            {!isSellerPreference &&
              !isEditing &&
              !isNil(offer.terms.increasedDepositPercentage) &&
              formatPrettyPercentage(offer.terms.increasedDepositPercentage, 2) +
                ' | ' +
                formatCurrency(offer.terms.increasedDepositDollar)}
            {isEditing && 'Details being edited'}
          </SectionItem>
          <SectionTitle>{isSellerPreference && 'Additional Deposit Day(s) to deliver to escrow'}</SectionTitle>
          <SectionItem
            highlight={globalCounter && offer.terms.increasedDepositDays !== globalCounter.terms.increasedDepositDays}
          >
            {!isSellerPreference &&
              !isEditing &&
              !isNil(offer.terms.increasedDepositDays) &&
              offer.terms.increasedDepositDays + ' Days'}
            {isEditing && 'Details being edited'}
          </SectionItem>
          <SectionTitle>{isSellerPreference && 'Method of Purchase'}</SectionTitle>
          <SectionItem highlight={globalCounter && offer.terms.loanType !== globalCounter.terms.loanType}>
            {!isSellerPreference &&
              !isGlobalCounter &&
              !isEditing &&
              (offer.terms.loan ? offer.terms.loanType : 'CASH')}
            {isEditing && 'Details being edited'}
          </SectionItem>
          <SectionTitle>{isSellerPreference && 'Down Payment'}</SectionTitle>
          <SectionItem
            highlight={
              globalCounter &&
              (offer.terms.downPaymentPercentage !== globalCounter.terms.downPaymentPercentage ||
                offer.terms.downPaymentDollar !== globalCounter.terms.downPaymentDollar)
            }
          >
            {!isSellerPreference &&
              !isGlobalCounter &&
              !isEditing &&
              (offer.terms.downPaymentPercentage ?? '') + '% | ' + formatCurrency(offer.terms.downPaymentDollar)}
            {isEditing && 'Details being edited'}
          </SectionItem>
          <SectionTitle>{isSellerPreference && 'Ownership'}</SectionTitle>
          <SectionItem
            highlight={
              globalCounter &&
              offer.terms.ownershipPurpose &&
              offer.terms.ownershipPurpose !== globalCounter.terms.ownershipPurpose
            }
          >
            {!isSellerPreference && !isGlobalCounter && !isEditing && offer.terms.ownershipPurpose}
            {isEditing && 'Details being edited'}
          </SectionItem>
        </>
      )}
    </Container>
  );
}

OfferTerms.propTypes = {
  isSellerPreference: PropTypes.bool,
  placeholder: PropTypes.bool,
  sellerPreferenceOffer: PropTypes.object,
  offer: PropTypes.object,
  globalCounter: PropTypes.object,
  isGlobalCounter: PropTypes.bool,
  isEditing: PropTypes.bool,
  onEditOfferAmount: PropTypes.func,
};
export default OfferTerms;
