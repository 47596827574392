import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Container, SectionTitle, SectionItem, SeeMore } from './offerCommon.styled';
import { formatCurrency, isParamsNil } from 'utils';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

function OfferCreditItems({
  isSellerPreference = false,
  placeholder = false,
  sellerPreferenceOffer,
  offer,
  globalCounter,
  isEditing = false,
}) {
  const showAdditionalItems = (type, items) => {
    const itemsLabel = {
      includedItems: 'Included Items',
      excludedItems: 'Excluded Items',
      lienItems: 'Leased Items',
    };
    //const items = offer.creditItems[type] || [];

    MySwal.fire({
      title: itemsLabel[type],
      html: (
        <>
          {items.map((item, index) => (
            <div key={`${item.name}-${index}`}>{item.name}</div>
          ))}
        </>
      ),
      showCloseButton: true,
    });
  };

  const allIncludedItems = useMemo(() => {
    return (
      offer?.creditItems?.includedStateSpecifiedItems && offer?.creditItems?.includedStateSpecifiedItems.length > 0
        ? offer?.creditItems?.includedStateSpecifiedItems
        : []
    ).concat(
      offer?.creditItems?.includedItems && offer?.creditItems?.includedItems.length > 0
        ? offer?.creditItems?.includedItems
        : []
    );
  }, [offer]);

  return (
    <Container preference={isSellerPreference} $placeholder={placeholder}>
      {!placeholder && (
        <>
          <SectionTitle
            match={
              !isSellerPreference &&
              !isParamsNil(
                sellerPreferenceOffer.sellerPaysBuyerBrokerPercentage,
                sellerPreferenceOffer.sellerPaysBuyerBrokerDollar
              ) &&
              sellerPreferenceOffer.sellerPaysBuyerBrokerPercentage ===
                offer.creditItems?.sellerPaysBuyerBrokerPercentage &&
              formatCurrency(sellerPreferenceOffer.sellerPaysBuyerBrokerDollar) ===
                formatCurrency(offer.creditItems?.sellerPaysBuyerBrokerDollar)
            }
          >
            {isSellerPreference && "Seller pays the Buyer Broker's commission"}
          </SectionTitle>
          <SectionItem
            highlight={
              globalCounter &&
              ((offer.creditItems?.sellerPaysBuyerBrokerPercentage ??
                '' !== globalCounter.creditItems?.sellerPaysBuyerBrokerPercentage ??
                '') ||
                formatCurrency(offer.creditItems?.sellerPaysBuyerBrokerDollar) !==
                  formatCurrency(globalCounter.creditItems?.sellerPaysBuyerBrokerDollar))
            }
          >
            {isSellerPreference &&
              !isParamsNil(
                sellerPreferenceOffer?.sellerPaysBuyerBrokerPercentage,
                sellerPreferenceOffer?.sellerPaysBuyerBrokerDollar
              ) &&
              sellerPreferenceOffer?.sellerPaysBuyerBrokerPercentage +
                '% | ' +
                formatCurrency(sellerPreferenceOffer?.sellerPaysBuyerBrokerDollar)}
            {!isSellerPreference &&
              !isEditing &&
              (offer.creditItems?.sellerPaysBuyerBrokerPercentage ?? '') +
                '% | ' +
                formatCurrency(offer.creditItems?.sellerPaysBuyerBrokerDollar ?? 0)}
            {isEditing && 'Details being edited'}
          </SectionItem>

          <SectionTitle
            match={
              !isSellerPreference &&
              !isParamsNil(sellerPreferenceOffer.closingCostPercentage, sellerPreferenceOffer.closingCostCredit) &&
              sellerPreferenceOffer.closingCostPercentage === offer.creditItems?.closingCostCreditPercentage &&
              formatCurrency(sellerPreferenceOffer.closingCostCredit) ===
                formatCurrency(offer.creditItems?.closingCostCreditDollar)
            }
          >
            {isSellerPreference && 'Closing Cost Credit'}
          </SectionTitle>
          <SectionItem
            highlight={
              globalCounter &&
              ((offer.creditItems?.closingCostCreditPercentage ??
                '' !== globalCounter.creditItems?.closingCostCreditPercentage ??
                '') ||
                formatCurrency(offer.creditItems?.closingCostCreditDollar) !==
                  formatCurrency(globalCounter.creditItems?.closingCostCreditDollar))
            }
          >
            {isSellerPreference &&
              !isParamsNil(sellerPreferenceOffer?.closingCostPercentage, sellerPreferenceOffer?.closingCostCredit) &&
              sellerPreferenceOffer?.closingCostPercentage +
                '% | ' +
                formatCurrency(sellerPreferenceOffer?.closingCostCredit)}
            {!isSellerPreference &&
              !isEditing &&
              (offer.creditItems?.closingCostCreditPercentage ?? '') +
                '% | ' +
                formatCurrency(offer.creditItems?.closingCostCreditDollar ?? 0)}
            {isEditing && 'Details being edited'}
          </SectionItem>
          <SectionTitle>{isSellerPreference && 'Included Items'}</SectionTitle>
          <SectionItem
            highlight={
              globalCounter &&
              offer.creditItems?.includedItems?.length > 0 &&
              globalCounter.creditItems?.includedItems?.length > 0
                ? offer.creditItems?.includedItems.filter(
                    (item) => globalCounter.creditItems?.includedItems.find((i) => i === item) === undefined
                  ).length > 0
                : false
            }
            height={80}
          >
            {!isSellerPreference &&
              !isEditing &&
              (allIncludedItems.length > 0 ? (
                <>
                  {allIncludedItems.slice(0, 1).map((item, index) => (
                    <div key={`included-stateitem-${index}`}>{item.name}</div>
                  ))}
                  {allIncludedItems.length > 2 && (
                    <SeeMore onClick={() => showAdditionalItems('includedItems', allIncludedItems)}>
                      See additional items ►
                    </SeeMore>
                  )}
                </>
              ) : (
                <span>No Items</span>
              ))}
            {isEditing && 'Details being edited'}
          </SectionItem>
          <SectionTitle>{isSellerPreference && 'Excluded Items'}</SectionTitle>
          <SectionItem
            highlight={
              globalCounter &&
              offer.creditItems?.excludedItems?.length > 0 &&
              globalCounter.creditItems?.excludedItems?.length > 0
                ? offer.creditItems?.excludedItems.filter(
                    (item) => globalCounter.creditItems?.excludedItems.find((i) => i === item) === undefined
                  ).length > 0
                : false
            }
          >
            {!isSellerPreference &&
              !isEditing &&
              (offer.creditItems?.excludedItems?.length > 0 ? (
                <>
                  {offer.creditItems?.excludedItems.slice(0, 2).map((item, index) => (
                    <div key={`excluded-${item.name}-${index}`}>{item.name}</div>
                  ))}
                  {offer.creditItems.excludedItems.length > 2 && (
                    <SeeMore onClick={() => showAdditionalItems('excludedItems', offer.creditItems.excludedItems)}>
                      See additional items ►
                    </SeeMore>
                  )}
                </>
              ) : (
                <span>No Items</span>
              ))}
            {isEditing && 'Details being edited'}
          </SectionItem>
          <SectionTitle>{isSellerPreference && 'Leased Items'}</SectionTitle>
          <SectionItem
            highlight={
              globalCounter &&
              offer.creditItems?.lienItems?.length > 0 &&
              globalCounter.creditItems?.lienItems?.length > 0
                ? offer.creditItems?.lienItems?.filter(
                    (item) => globalCounter.creditItems?.lienItems?.find((i) => i === item) === undefined
                  ).length > 0
                : false
            }
          >
            {!isSellerPreference &&
              !isEditing &&
              (offer.creditItems?.lienItems?.length > 0 ? (
                <>
                  {offer.creditItems?.lienItems?.slice(0, 2).map((item, index) => (
                    <div key={`leased-${item.name}-${index}`}>{item.name}</div>
                  ))}
                  {offer.creditItems?.lienItems?.length > 2 && (
                    <SeeMore onClick={() => showAdditionalItems('lienItems', offer.creditItems.lienItems)}>
                      See additional items ►
                    </SeeMore>
                  )}
                </>
              ) : (
                <span>No Items</span>
              ))}
            {isEditing && 'Details being edited'}
          </SectionItem>
        </>
      )}
    </Container>
  );
}

OfferCreditItems.propTypes = {
  isSellerPreference: PropTypes.bool,
  placeholder: PropTypes.bool,
  sellerPreferenceOffer: PropTypes.object,
  offer: PropTypes.object,
  globalCounter: PropTypes.object,
};
export default OfferCreditItems;
